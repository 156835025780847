import { collection, doc, getDoc, getDocs, where } from "firebase/firestore"
import { auth, db } from "../config/config"


export const checkUserType = async () => {
    return new Promise(async (resolve, reject) => {
        await getDoc(doc(db, "users", auth.currentUser.uid)).then(async (userDoc) => {
            if (userDoc.exists()) {
                let roles = userDoc.data().roles;
                if (roles?.owner === true) {
                    return resolve({
                        role: "owner",
                        goToDashboard: true
                    });
                } else if (roles?.chatter === true) {
                    return resolve({
                        role: "chatter",
                        goToDashboard: false
                    });
                } else {
                    return resolve({
                        goToDashboard: false
                    });
                }
            }
        })
    })
}