import { logEvent } from "firebase/analytics";
import { analytics, auth } from "../config/config";

export const saveAccountInfo = async (fullName, isAgency = false, agencyCode) => {
  return new Promise(async (resolve, reject) => {
    return auth.currentUser.getIdToken(true).then(async (token) => {
      if (isAgency === true) {
        return await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/stripe/createCustomer`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            email: auth.currentUser.email,
            fullName: fullName,
            firebaseUID: auth.currentUser.uid
          })
        }).then((r) => r.json()).then(async (data) => {
          console.log(data);
          const customerID = data.customerId; 
          return await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/account/saveAccountInfo`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
              firebaseUID: auth.currentUser.uid,
              fullName: fullName,
              isAgency: isAgency,
              customerID: customerID,
              agencyCode: agencyCode === '' ? null : agencyCode
            })
          }).then((r) => r.json()).then(async (data) => {
            logEvent(analytics, "account_info_saved");
            return resolve({
                status: "success"
            })
          }).catch((error) => {
            return reject(error)
          })
        }).catch((error) => {
          return reject(error)
        })
      } else {
        return await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/account/saveAccountInfo`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            firebaseUID: auth.currentUser.uid,
            fullName: fullName,
            isAgency: isAgency,
            agencyCode: agencyCode === '' ? null : agencyCode
          })
        }).then((r) => r.json()).then(async (data) => {
          // return await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/agencies/linkChatter`, {
          //   method: "POST",
          //   headers: {
          //     'Content-Type': 'application/json',
          //     'Authorization': `Bearer ${token}`,
          //   },
          //   body: JSON.stringify({
          //     firebaseUID: auth.currentUser.uid,
          //     agencyCode: document.querySelector("[data-id='createAccountAgencyCode']").value
          //   })
          // }).then((r) => r.json()).then(async (data) => {
          //   logEvent(analytics, "account_info_saved");
          //   console.log(data)
          //   if (data?.success === true) {
          //     return resolve({
          //         status: "success"
          //     })
          //   } else {
          //     return reject(data)
          //   }
          // })
          return resolve({
            status: "success"
          })
        }).catch((error) => {
          return reject(error)
        })
      }
    })
  })
}