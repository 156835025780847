import { createUserWithEmailAndPassword } from "firebase/auth";
import { analytics, auth } from "../config/config";
import { logEvent, setUserId } from "firebase/analytics";

export const createAccount = async (email, password) => {
  return new Promise(async (resolve, reject) => {
    return await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        logEvent(analytics, "account_created");
        setUserId(analytics, auth.currentUser.uid);
        console.log(userCredential)
        return auth.currentUser.getIdToken(true).then(async (token) => {
          return await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/account/createAccount`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
              email: auth.currentUser.email,
              firebaseUID: auth.currentUser.uid
            })
          }).then((r) => r.json()).then(async (data) => {
            return resolve({
              status: "success"
            })
          }).catch((error) => {
            return reject(error)
          })
        })
    }).catch((error) => {
      return reject(error)
    })
  })
}