import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { browserLocalPersistence, getAuth, setPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import * as Sentry from "@sentry/browser";

const firebaseConfig = {
  apiKey: `${import.meta.env.VITE_apiKey}`,
  authDomain: `${import.meta.env.VITE_authDomain}`,
  projectId: `${import.meta.env.VITE_projectId}`,
  storageBucket: `${import.meta.env.VITE_storageBucket}`,
  messagingSenderId: `${import.meta.env.VITE_messagingSenderId}`,
  appId: `${import.meta.env.VITE_appId}`,
  measurementId: `${import.meta.env.VITE_measurementId}`,
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);
const feedback =  Sentry.feedbackIntegration({
  colorScheme: "light",
  showBranding: false,
  autoInject: false,
})

if (process.env.NODE_ENV === "development") {
  connectFunctionsEmulator(functions, "localhost", 5003);
  Sentry.init({
    dsn: "https://0927faffc8f7100036d36c0c21852755@sentry.accesslabs.io/3",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/botly-dev\.web\.app\//],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
} else {
  Sentry.init({
    dsn: "https://0e55dfb4b853a37dd73d4efd300a3101@sentry.accesslabs.io/4",
    /*dsn: "https://0927faffc8f7100036d36c0c21852755@sentry.accesslabs.io/3",*/
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      feedback
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/dojo\.getbotly\.com\//, /^https:\/\/botly-dev\.web\.app\//],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });  
}

export { auth, db, functions, analytics, feedback }