import { FieldValue, arrayRemove, arrayUnion, collection, doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc } from "firebase/firestore";
import { analytics, auth, db, feedback, functions } from "./config/config";
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth"
import $ from "jquery";
import axios from "axios";
import crypto from "crypto";
import { httpsCallable } from "firebase/functions";
import SpeedTest from "@cloudflare/speedtest";
import { createAccount } from "./utils/createAccount.util";
import { saveAccountInfo } from "./utils/saveAccountInfo.util";
import { logEvent, setUserId } from "firebase/analytics";
import { checkUserType } from "./utils/checkUserType.util";
import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more.src";
import HighchartsSolidGauge from "highcharts/modules/solid-gauge";
import { millisToMinutesAndSeconds } from "./utils/milliseoncdsToMinutes.util";
import { where } from "firebase/firestore/lite";
import * as Sentry from "@sentry/browser";
import Intercom from '@intercom/messenger-js-sdk';

// Inititialize Highcharts
HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

var quizQuestions = [
  "<b>Respond to the following scenario:</b></br><i style='color: #ADADAD;'>John has come online...</i></br>Hey baby, just finished work and couldn’t help but think of you on my way home. Have I been on your mind?",
  "<i style='color: #ADADAD;'>John replies</i></br>I just made some dinner. To be honest it’s overcooked. If only I had you here to save me from moments like this 🤦‍♂️",
  "A new subscriber has just signed up. Write a welcome message to them.",
  "A subscriber asks for a type of content that is not allowed by the platform. How do you respond?",
  "How would you approach engaging with a new subscriber for the first time?",
  "Provide an example of a message you would send to a subscriber to keep them engaged.",
  "How would you keep the conversation interesting for long-term subscribers?",
  "How would you handle a subscriber who seems upset or dissatisfied with the content?",
  "How would you upsell a piece of premium content to a subscriber?",
  "Write a caption for a new photo set that encourages subscribers to purchase.",
  "How do you customize your interactions to cater to individual subscriber preferences?",
  "How do you manage responding to multiple subscribers at once without sacrificing quality?"
];
var fanMessages;
var modelMessages;
var currentQuestionNumber = 0;
var currentFanMessageNumber = 0;
var currentModelMessageNumber = 0;
window.$ = window.jQuery = $;
var startDate = null;
var wpmArray = [];
var stripe = Stripe("pk_test_51LT4TdE9LPhG2ozyTiik7v2bEOQGdxiKKzvrQvP93xx3IjNtimgDTknBHK6Zjb7a4d8KngH3mfDnOK8npagS5nh400NUDCinrZ");

var loginDiv = document.querySelector("[data-id='loginDiv']");
var loginEmail = document.querySelector("[data-id='loginEmail']");
var loginPassword = document.querySelector("[data-id='loginPassword']");
var loginDivErrorLbl = loginDiv.querySelector(".errorLbl");
var loginBtn = document.querySelector("[data-id='loginBtn']");
var chatDiv = document.querySelector("[data-id='chatDiv']");
var chatterDiv = document.querySelector("[data-id='chatterDiv']");
var introductionArea = document.querySelector(".introductionArea");
var messageArea = document.querySelector(".messageArea");
var monogramH2 = document.querySelector("[data-id='monogram']");
var customerNameH3 = document.querySelector("[data-id='customerName']");
var messageText = document.querySelector("[data-id='messageText']");
var messageSendBtn = document.querySelector("[data-id='messageSendBtn']");
var newConversationDisplay = document.querySelector("[data-id='newConversationDisplay']");
var proficiencyTestIntro = document.querySelector("[data-id='proficiencyTestIntro']");
// var suggestionsDiv = document.querySelector("[data-id='suggestionsDiv']");
var messageList = document.querySelector("[data-id='messageList']");
var bottomMenuButton = document.querySelector("[data-id='bottomMenuButton']");
var bottomMenuList = document.querySelector("[data-id='bottomMenuList']");
var billingBtn = document.querySelector("[data-id='billingBtn']");
var createAccountBtn = document.querySelector("[data-id='createAccountBtn']");
var createAccountEmail = document.querySelector("[data-id='createAccountEmail']");
var createAccountPassword = document.querySelector("[data-id='createAccountPassword']");
var createAccountDiv = document.querySelector("[data-id='createAccountDiv']");
var createAccountStartBtn = document.querySelector("[data-id='createAccountStartBtn']");
var createAccountScreenErrorLbl = createAccountDiv.querySelector(".errorLbl");
var accountInfoScreen = document.querySelector("[data-id='accountInfoScreen']");
var createAccountFullName = document.querySelector("[data-id='createAccountFullName']");
var agencyRadioDiv = document.querySelector("[data-id='agencyRadioDiv']");
var agencyRadio = document.querySelector("[data-id='agencyRadio']");
var chatterRadioDiv = document.querySelector("[data-id='chatterRadioDiv']");
var chatterRadio = document.querySelector("[data-id='chatterRadio']");
var saveAccountInfoBtn = document.querySelector("[data-id='saveAccountInfoBtn']");
var accountInfoScreenErrorLbl = accountInfoScreen.querySelector(".errorLbl");
var mobileMenuBtn = document.querySelector("[data-id='mobileMenuBtn']");
var mobileNewChatBtn = document.querySelector("[data-id='mobileNewChatBtn']");
var continueToProficiencyTest = document.querySelector("[data-id='continueToProficiencyTest']");
var introductionBtn = document.querySelector("[data-id='introduction']");
var proficiencyTest = document.querySelector("[data-id='proficiencyTest']");
var trainWithModel = document.querySelector("[data-id='trainWithModel']");
var trainWithFan = document.querySelector("[data-id='trainWithFan']");
var proficiencyTestArea = document.querySelector(".proficiencyTestArea");
var startProficiencyTest = document.querySelector("[data-id='startProficiencyTest']");
var proficiencyTestScreen = document.querySelector(".proficiencyTestScreen");

var fullName;
var monogram;
var chatID;
var customerID;

axios.defaults.headers.post['Content-Type'] = 'application/json';

const login = async (e) => {
  let email = loginEmail.value;
  let password = loginPassword.value;
  if (email !== "" && password !== "") {
    loginBtn.innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`;
    await signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
      console.log(userCredential);
      logEvent(analytics, "login");
      setUserId(analytics, auth.currentUser.uid);
      loginDiv.style.display = "none";
      loginBtn.innerText = `Continue`;
      loadChat();
    }).catch((e) => {
      console.error(e)
      loginBtn.innerText = `Continue`;
      loginDivErrorLbl.style.display = "flex";
    })
  } else {
    loginDivErrorLbl.style.display = "flex";
  }
}

const loadAgencyScreen = async () => {
  const agencyDiv = document.querySelector("[data-id='agencyDiv']");
  const agencyDashboardChattersTbl = document.querySelector("#agencyDashboardChattersTbl");
  monogramH2 = agencyDiv.querySelector("[data-id='monogram']");
  customerNameH3 = agencyDiv.querySelector("[data-id='customerName']");
  agencyDiv.querySelector("[data-id='bottomMenuButton']").addEventListener("click", function (e) {
    e.preventDefault()
    e.stopImmediatePropagation()
    agencyDiv.querySelector("[data-id='bottomMenuList']").classList.add("active")
  });  
  agencyDiv.querySelector("[data-id='bottomMenuButton']").addEventListener("mouseover", function () {
    agencyDiv.querySelector("[data-id='bottomMenuList']").classList.add("active")
  });  
  agencyDiv.querySelector("[data-id='bottomMenuList']").addEventListener("mouseleave", (e) => {
    agencyDiv.querySelector("[data-id='bottomMenuList']").classList.remove("active")
  });
  await getDoc(doc(db, "users", auth.currentUser.uid)).then(async (userDoc) => {
    if (userDoc.exists()) {
      fullName = userDoc.data().fullName;
      if (fullName.split(" ").length > 1) {
        monogram = Array.from(fullName)[0] + (Array?.from(fullName?.split(" ")?.[1])?.[0] ?? "");
      } else {
        monogram = Array.from(fullName)[0];
      }
      monogramH2.innerText = monogram;
      customerNameH3.innerText = fullName;
      const agencyID = userDoc.data().agencyID;
      await auth.currentUser.getIdToken(true).then(async (idToken) => {
        await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/agencies/getDashboard`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ idToken,
          },
          body: JSON.stringify({
            agencyID: agencyID
          })
        }).then((res) => res.json()).then(async (res) => {
          console.log(res);
          let chatters = res.data;
          let chatterKeys = Object.keys(chatters);
          let allTestsArray  = [];
          const scoreArray = [];
          const completeArray = [];
          const incompleteArray = [];
          const timeTakenArray = [];
          const average = array => array.reduce((a, b) => a + b) / array.length;
          console.log(chatterKeys)
  
          for (const chatter of chatterKeys) {
            let tests = chatters[chatter]?.tests ?? [];
            allTestsArray = [...allTestsArray,...tests];
            tests.forEach((test) => {
              if (test.metadata.status === "complete") {
                completeArray.push(test);
                scoreArray.push(test.scoreRecorded.average_score);
                const startDateMilliseconds = new Date(test.testStarted).getTime();
                const endDateMilliseconds = new Date(test.dateCompleted).getTime();
                console.log(startDateMilliseconds)
                console.log(endDateMilliseconds)
                const timeTaken = endDateMilliseconds - startDateMilliseconds;
                console.log(timeTaken)
                timeTakenArray.push(timeTaken)
              } else {
                incompleteArray.push(test);
              }
            })
            agencyDiv.querySelector("[data-id='agencySidebar']").querySelector(".scrollableInnerDiv").innerHTML += `<div class="chatHistoryItem" data-id="${chatter}">
              <p>${chatters[chatter].fullName}</p>
            </div>`;
            agencyDiv.querySelector(`[data-id='${chatter}']`).addEventListener("click", (e) => {
              e.stopImmediatePropagation();
              agencyDiv.querySelectorAll(".chatHistoryItem").forEach((chatHistoryItem) => {
                chatHistoryItem.classList.remove("selected");
              });
              agencyDiv.querySelector(`[data-id='${chatter}']`).classList.add("selected");
              agencyDiv.querySelector("[data-id='agencyTrainersDiv']").style.display = "none";
              loadChatterScreen(chatter);
            })
            const row = document.createElement("tr");
            const col1 = document.createElement("td");
            const col2 = document.createElement("td");
            const col3 = document.createElement("td");
            const col4 = document.createElement("td");
            const col5 = document.createElement("td");
            col1.innerText = chatters[chatter].fullName;
            col2.innerText = chatters[chatter]?.tests?.[0]?.metadata?.status === "complete" ? chatters[chatter]?.tests?.[0]?.scoreRecorded?.average_score + "/100" : "Not Completed";
            col3.innerText = chatters[chatter]?.tests?.[0]?.metadata?.status === "complete" ? new Date(chatters[chatter].tests[0].testStarted).toLocaleDateString() : "N/A";
            col4.innerText = chatters[chatter]?.tests?.[0]?.metadata?.status === "complete" ? millisToMinutesAndSeconds(new Date(chatters[chatter].tests[0].dateCompleted).getTime() - new Date(chatters[chatter].tests[0].testStarted).getTime()) : "N/A";
            if (chatters[chatter]?.tests?.[0]?.scoreRecorded?.average_score > 60) {
              col2.style.color = "#E9B225";
            } else if (chatters[chatter]?.tests?.[0]?.scoreRecorded?.average_score > 90) {
              col2.style.color = "#06BF24";
            } else {
              col2.style.color = "#E92525";
            }
            col5.innerHTML = `<button class="viewTestsBtn" data-chatter="${chatter}"><i class="fa-solid fa-right-from-bracket"></i> View Chatter</button>`
            row.appendChild(col1);
            row.appendChild(col2);
            row.appendChild(col3);
            row.appendChild(col4);
            row.appendChild(col5);
            agencyDashboardChattersTbl.querySelector("tbody").appendChild(row);
            agencyDiv.querySelector(`[data-chatter='${chatter}']`).addEventListener("click", (e) => {
              e.stopImmediatePropagation();
              agencyDiv.querySelectorAll(".chatHistoryItem").forEach((chatHistoryItem) => {
                chatHistoryItem.classList.remove("selected");
              });
              agencyDiv.querySelector(`[data-id='${chatter}']`).classList.add("selected");
              agencyDiv.querySelector("[data-id='agencyTrainersDiv']").style.display = "none";
              loadChatterScreen(chatter);
            })
          }
          agencyDiv.querySelector("[data-id='homeScreen']").classList.add("selected");
          agencyDiv.querySelector("[data-id='homeScreen']").addEventListener("click", (e) => {
            agencyDiv.querySelector("[data-id='screenName']").innerText = "🏠 Home";
            agencyDiv.querySelector("[data-id='agencyTrainersDiv']").style.display = "none";
            if (window.visibleChatter !== undefined) {
              document.querySelector(`[data-chatterScreen='${window.visibleChatter}']`).remove();
              agencyDiv.querySelectorAll(".chatHistoryItem").forEach((chatHistoryItem) => {
                chatHistoryItem.classList.remove("selected");
              });
              document.querySelector("[data-id='agencyMainDiv']").style.display = "flex";
              agencyDiv.style.display = "flex";
              agencyDiv.querySelector("[data-id='homeScreen']").classList.add("selected");
            } else {
              agencyDiv.querySelectorAll(".chatHistoryItem").forEach((chatHistoryItem) => {
                chatHistoryItem.classList.remove("selected");
              });
              document.querySelector("[data-id='agencyMainDiv']").style.display = "flex";
              agencyDiv.style.display = "flex";
              agencyDiv.querySelector("[data-id='homeScreen']").classList.add("selected");
            }
          })
          agencyDiv.querySelector("[data-id='agencyTrainers']").addEventListener("click", async (e) => {
            agencyDiv.querySelector("[data-id='screenName']").innerText = "💬 Chatter Trainers";
            if (window.visibleChatter !== undefined) {
              document.querySelector(`[data-chatterScreen='${window.visibleChatter}']`).remove();
            }
            window.visibleChatter = undefined;
            agencyDiv.querySelectorAll(".chatHistoryItem").forEach((chatHistoryItem) => {
              chatHistoryItem.classList.remove("selected");
            });
            agencyDiv.querySelector("[data-id='agencyTrainers']").classList.add("selected");
            document.querySelector("[data-id='agencyMainDiv']").style.display = "none";
            agencyDiv.querySelector("[data-id='agencyTrainersDiv']").style.display = "flex";
            await getDoc(doc(db, "users", auth.currentUser.uid)).then(async (userDoc) => {
              if (userDoc.exists()) {
                fullName = userDoc.data().fullName;
                if (fullName.split(" ").length > 1) {
                  monogram = Array.from(fullName)[0] + (Array?.from(fullName?.split(" ")?.[1])?.[0] ?? "");
                } else {
                  monogram = Array.from(fullName)[0];
                }
                monogramH2.innerText = monogram;
                customerNameH3.innerText = fullName;
              }
            })
            auth.currentUser.getIdToken(true).then(async (idToken) => {
              await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/trainers/getTrainers`, {
                method: "POST",
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer '+ idToken,
                },
                body: JSON.stringify({
                  firebaseUID: auth.currentUser.uid,
                })
              }).then((res) => res.json()).then(async (res) => {
                console.log(res);
                document.querySelector(".trainerCards").innerHTML = '';
                for (const trainer of Object.keys(res?.trainers)) {
                    document.querySelector(".trainerCards").innerHTML += `
                      <div class='trainerCard'>
                        <h1>${res.trainers[trainer].trainerName}</h1>
                        <button class="button-primary">
                          View Results
                        </button>
                        <div>
                          <h4>Model Details:</h4>
                          <p><b>Name:</b> ${res.trainers[trainer].modelDetails.name}</p>
                          <p><b>Age:</b> ${res.trainers[trainer].modelDetails.age}</p>
                          <p><b>Location:</b> ${res.trainers[trainer].modelDetails.location}</p>
                          <p><b>Interests:</b> ${res.trainers[trainer].modelDetails.interests}</p>
                        </div>
                      </div>
                    `
                }
              })
            })
          })
          console.log(allTestsArray)
          if (chatterKeys.length <= 0) {
            const row = document.createElement("tr");
            const col1 = document.createElement("td");
            col1.classList.add("full-width-col")
            col1.innerHTML = `
              <div class='addChatterDiv'>
                <p>No chatters found</p>
                <button class="addChatterBtn" id="addFirstChatter">Add Chatter</button>
              </div>`;
            row.appendChild(col1);
            agencyDashboardChattersTbl.querySelector("tbody").appendChild(row);
            agencyDiv.querySelector("[data-id='agencySidebar']").querySelector(".scrollableInnerDiv").innerHTML += `
              <div class='addChatterDiv'>
                <p>No chatters found</p>
                <button class="addChatterBtn" id="addFirstChatter">Add Chatter</button>
              </div>`;
            document.querySelectorAll(".addChatterBtn").forEach((btn) => {
                btn.addEventListener("click", async () => {
                  btn.innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`;
                  return auth.currentUser.getIdToken(true).then(async (idToken) => {
                    return await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/stripe/checkAgencySubscription`, {
                      method: "POST",
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + idToken,
                      },
                      body: JSON.stringify({
                        firebaseUID: auth.currentUser.uid,
                      })
                    }).then((res) => res.json()).then(async (res) => {
                      console.log(res)
                      if (res.needsAgencySubscription === true) {
                        btn.innerText = "Add Chatter";
                        document.querySelector("#subscriptionModal").style.display = "flex";
                        document.querySelector("#subscriptionModal").querySelector("#subscribeBtn").addEventListener("click", async () => {
                          document.querySelector("#subscriptionModal").querySelector("#subscribeBtn").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`;
                          let clientSecret = res.stripeData.clientSecret;
                          const options = {
                            clientSecret: clientSecret,
                            appearance: {
                              theme: "flat",
                              variables: {
                                colorPrimary: '#2F41E3',
                                fontFamily: 'Montserrat, sans-serif',
                                fontWeight: "600",
                                borderRadius: "8px",
                                colorText: "#333333",
                                colorDanger: "#E92525",
                                fontSizeBase: "16px",
                                colorTextPlaceholder: "#ADADAD",
                                colorSuccess: "#00CF91",
                                fontLineHeight: "20px",
                                spacingUnit: "4px"
                              },
                              rules: {
                                ".Input:focus": {
                                  border: "1px solid #2F41E3",
                                  boxShadow: "none",
                                },
                                ".Input": {
                                  backgroundColor: "#FFFFFF",
                                  border: "1px solid #CECECE",
                                  boxShadow: "none",
                                  borderRadius: "8px",
                                  padding: "12px 16px",
                                },
                                ".Input::placeholder": {
                                  color: "#ADADAD"
                                }
                              }
                            }
                          }
                          const elements = stripe.elements(options);
                          const addressElement = elements.create('address', { 
                            mode: 'billing',
                            defaultValues: {
                              name: res.stripeData.fullName,
                            }
                          });
                          const paymentElement = elements.create('payment');
                          addressElement.mount('#address-element');
                          paymentElement.mount('#payment-element');
                    
                          document.getElementById('paymentModal').style.display = "flex";
                  
                          const form = document.getElementById('payment-form');
                          const submitBtn = document.getElementById('submit');
                  
                          const handleError = (error) => {
                            const messageContainer = document.querySelector('#error-message');
                            messageContainer.textContent = error.message;
                            submitBtn.disabled = false;
                          }
      
                          form.addEventListener('submit', async (event) => {
                            // We don't want to let default form submission happen here,
                            // which would refresh the page.
                            event.preventDefault();
                  
                            // Prevent multiple form submissions
                            if (submitBtn.disabled) {
                              return;
                            }
                  
                            // Disable form submission while loading
                            submitBtn.disabled = true;
                  
                            // Trigger form validation and wallet collection
                            const { error: submitError } = await elements.submit();
                  
                            if (submitError) {
                              handleError(submitError);
                              return;
                            }
                  
                            // Confirm the SetupIntent using the details collected by the Payment Element
                            const { error } = await stripe.confirmPayment({
                              elements,
                              clientSecret,
                              confirmParams: {
                                return_url: `${window.location.href}`,
                              },
                            });
                  
                            if (error) {
                              // This point is only reached if there's an immediate error when
                              // confirming the setup. Show the error to your customer (for example, payment details incomplete)
                              handleError(error);
                            }
                          });
                          document.querySelector("#subscriptionModal").querySelector("#subscribeBtn").innerText = `Subscribe`;
                          document.querySelector("#subscriptionModal").style.display = "none";
                          document.querySelector("#paymentModal").style.display = "flex";
                        })
                      } else {
                        return auth.currentUser.getIdToken(true).then(async (idToken) => {
                          return await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/agencies/getAgencyCode`, {
                            method: "POST",
                            headers: {
                              'Content-Type': 'application/json',
                              'Authorization': 'Bearer ' + idToken,
                            },
                            body: JSON.stringify({
                              firebaseUID: auth.currentUser.uid,
                            })
                          }).then((res) => res.json()).then(async (res) => {
                            document.getElementById("chatterModal").style.display = "flex";
                            document.getElementById("agencyIDDisplay").innerText = res.agencyCode;
                          })
                        });                                      
                      }
  
                    }).catch((error) => {
                      console.error("Error:", error);
                    })
                  })
                })
            })
          } else {
            document.querySelectorAll(".addChatterBtn").forEach((btn) => {
              btn.addEventListener("click", async () => {
                return auth.currentUser.getIdToken(true).then(async (idToken) => {
                  return await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/agencies/getAgencyCode`, {
                    method: "POST",
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + idToken,
                    },
                    body: JSON.stringify({
                      firebaseUID: auth.currentUser.uid,
                    })
                  }).then((res) => res.json()).then(async (res) => {
                    document.getElementById("chatterModal").style.display = "flex";
                    document.getElementById("agencyIDDisplay").innerText = res.agencyCode;
                  })
                });
              })
            })
          }
          agencyDiv.style.display = "flex";
          Highcharts.chart('progressChart', {
            chart: {
                type: 'solidgauge',
                backgroundColor: "transparent",
                custom: {}
            },
            title: {
                text: 'Quiz Status',
                style: {
                    fontSize: '18px',
                    fontFamily: "'Montserrat', sans-serif",
                    lineHeight: "22px"
                }
            },
            legend: {
                enabled: false
            },
            credits: {
              enabled: false
            },
            yAxis: {
              min: 0,
              max: (completeArray.length + incompleteArray.length) !== 0 ? (completeArray.length + incompleteArray.length) : 1,
              lineWidth: 0,
              tickPositions: []
            },
            plotOptions: {
              solidgauge: {
                dataLabels: {
                  enabled: true,
                  borderWidth: 0,
                  backgroundColor: 'none',
                  align: "center",
                  verticalAlign: "middle",
                  shadow: false,
                  style: {
                      fontSize: '16px'
                  },
                  useHTML: true,
                  formatter: function() {
                    return `<div style="display: flex; flex-direction: column; align-items: center; justify-content: center; font-weight: bold;">${this.series.name}<br>
                      <span style="display: flex; font-size: 1.5em; color: ${this.point.color}; text-align: center; flex: 1; font-weight: bold;">${this.point.y}/${completeArray.length + incompleteArray.length}</span></div>`
                  },
                  positioner: function (labelWidth) {
                      return {
                          x: (this.chart.chartWidth - labelWidth) / 2,
                          y: (this.chart.plotHeight / 2) + 15
                      };
                  }
                },
                linecap: 'round',
                stickyTracking: false,
                rounded: true
              }
            },
            tooltip: {
              enabled: false
            },
            pane: {
              startAngle: 0,
              endAngle: 360,
              background: [{
                outerRadius: '110%',
                innerRadius: '90%',
                borderWidth: 0,
                backgroundColor: "rgba(37, 233, 174, 0.1)"
              }, {
                outerRadius: '90%',
                innerRadius: '70%',
                borderWidth: 0,
                backgroundColor: "rgba(233, 37, 37, 0.1)"
              }]
            },
            series: [{
              name: 'Completed',
              data: [{
                color: "#25E9AE",
                radius: '110%',
                innerRadius: "90%",
                y: completeArray.length,
              }]
            }, {
              name: 'Incomplete',
              data: [{
                color: "#E92525",
                radius: '90%',
                innerRadius: "70%",
                y: incompleteArray.length,
              }]
            }]
          });
          Highcharts.chart('averageScore', {
            chart: {
              type: 'solidgauge',
              backgroundColor: "transparent",
              custom: {}
            },
            title: {
              text: 'Average Score',
              style: {
                  fontSize: '18px',
                  fontFamily: "'Montserrat', sans-serif",
                  lineHeight: "22px"
              }
            },
            legend: {
              enabled: false
            },
            credits: {
              enabled: false
            },
            yAxis: {
              min: 0,
              max: 100,
              lineWidth: 0,
              tickPositions: []
            },
            tooltip: {
              enabled: false
            },
            plotOptions: {
              solidgauge: {
                dataLabels: {
                  enabled: true,
                  borderWidth: 0,
                  backgroundColor: 'none',
                  align: "center",
                  verticalAlign: "middle",
                  shadow: false,
                  style: {
                      fontSize: '16px'
                  },
                  useHTML: true,
                  formatter: function() {
                    return `<div style="display: flex; flex-direction: column; align-items: center; justify-content: center; font-weight: bold;">${this.series.name}<br>
                      <span style="display: flex; font-size: 1.5em; color: ${this.point.color}; text-align: center; flex: 1; font-weight: bold;">${this.point.y}</span></div>`
                  },
                  positioner: function (labelWidth) {
                      return {
                          x: (this.chart.chartWidth - labelWidth) / 2,
                          y: (this.chart.plotHeight / 2) + 15
                      };
                  }
                },
                linecap: 'round',
                stickyTracking: false,
                rounded: true
              }
            },
            pane: {
              startAngle: 0,
              endAngle: 360,
              background: [{
                outerRadius: '110%',
                innerRadius: '90%',
                borderWidth: 0,
                backgroundColor: "rgba(37, 233, 174, 0.1)"
              }]
            },
            series: [{
              name: "Average Score",
              data: [{
                color: "#25E9AE",
                radius: '110%',
                innerRadius: "90%",
                name: 'Average Score',
                y: scoreArray.length > 0 ? average(scoreArray) : 0
              }]
            }]
          });
          document.getElementById("averageTestTime").innerText = `${scoreArray.length > 0? millisToMinutesAndSeconds(average(timeTakenArray)) : 0} minutes`;
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const redirectStatus = urlParams.get("redirect_status");
          const paymentIntentSecret = urlParams.get("payment_intent_client_secret");
          if (redirectStatus === "succeeded") {
            const { paymentIntent } = await stripe.retrievePaymentIntent(paymentIntentSecret);
            return auth.currentUser.getIdToken(true).then(async (idToken) => {
              return await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/stripe/setDefaultPaymentMethod`, {
                method: "POST",
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + idToken,
                },
                body: JSON.stringify({
                  firebaseUID: auth.currentUser.uid,
                  paymentIntent: paymentIntent
                })
              }).then((res) => res.json()).then(async (res) => {
                console.log(res);
                return await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/agencies/getAgencyCode`, {
                  method: "POST",
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + idToken,
                  },
                  body: JSON.stringify({
                    firebaseUID: auth.currentUser.uid,
                  })
                }).then((res) => res.json()).then(async (res) => {
                  document.getElementById("chatterModal").style.display = "flex";
                  document.getElementById("agencyIDDisplay").innerText = res.agencyCode;
                })
              })
            });      
          }
        }).catch((e) => {
          console.error(e)
        })
      })
    }
  }).catch((e) => {
    console.error(e)
  })
  
}

const loadChatterScreen = async (chatter) => {
  await getDoc(doc(db, "users", chatter)).then(async (chatterDoc) => {
    if (chatterDoc.exists()) {
      let chatterViewTemplate = document.querySelector("[data-id='chatterViewDiv']");
      let newChatterView = chatterViewTemplate.cloneNode(true);
      let fullName = chatterDoc.data().fullName;
      newChatterView.setAttribute("data-chatterScreen", chatter);
      document.querySelector(".loadingScreen").style.display = "flex";
      document.querySelector("[data-id='agencyMainDiv']").style.display = "none";
      document.querySelector("[data-id='agencyDiv']").querySelector(".chatScreen").style.display = "none";
      window.visibleChatter = chatter;
      await getDocs(query(collection(db, "users", chatter, "tests"))).then(async (querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          newChatterView.querySelector(".previousTests").querySelector(".locked").style.display = "none";
          newChatterView.querySelector(".previousTests").querySelector(".unlocked").style.display = "flex";
          newChatterView.querySelector(".assignedTests").querySelector(".locked").style.display = "none";
          newChatterView.querySelector(".assignedTests").querySelector(".unlocked").style.display = "flex";
          const averageScores = [];
          const creativityScores = [];
          const emotionalIntelligenceScores = [];
          const fluencyVocabularyScores = [];
          const grammarPunctuationScores = [];
          const internetScores = [];
          const wpmScores = [];
          const seriesHeaders = [];
          let wpmArray = [];
          const average = array => array.reduce((a, b) => a + b) / array.length;
          for (const doc of querySnapshot.docs) {
            let testDate = doc.data().testStarted.toDate();
            let scoreRecorded = doc.data()?.scoreRecorded;
            let rowDiv = document.createElement("div");
            let col1 = document.createElement("div");
            let col2 = document.createElement("div");
            let col3 = document.createElement("div");
            let col4 = document.createElement("div");
            let col4Btn = document.createElement("a");
            col4Btn.id = `viewResult_${doc.id}`;
            col4Btn.innerText = "View Result";
            rowDiv.classList.add("row")
            col1.innerText = testDate.toLocaleDateString();
            col2.innerText = scoreRecorded?.average_score !== undefined ? `${scoreRecorded?.average_score}/100` : "Not Completed";
            col3.innerText = scoreRecorded?.recommendation ?? "N/A";
            col4.appendChild(col4Btn);
            rowDiv.appendChild(col1);
            rowDiv.appendChild(col2);
            rowDiv.appendChild(col3);
            rowDiv.appendChild(col4);
            newChatterView.querySelector(".previousTests").querySelector(".unlocked").appendChild(rowDiv);
            if (doc.data()?.scoreRecorded !== undefined) {
              seriesHeaders.push(doc.data().testStarted.toDate().toLocaleDateString());
              newChatterView.querySelector(".overview").querySelector(".locked").style.display = "none";
              newChatterView.querySelector(".overview").querySelector(".unlocked").style.display = "flex";
              averageScores.push(scoreRecorded.average_score);
              creativityScores.push(scoreRecorded.creativity);
              emotionalIntelligenceScores.push(scoreRecorded.emotional_intelligence);
              fluencyVocabularyScores.push(scoreRecorded.fluency_vocabulary);
              grammarPunctuationScores.push(scoreRecorded.grammar_punctuation);
              internetScores.push(scoreRecorded.internet_score);
              wpmScores.push(scoreRecorded.wpm_score);
              wpmArray = [...doc.data().wpmArray ?? []];
              col4Btn.style.color = "#2F41E3";
              col4Btn.addEventListener("click", async (e) => {
                e.preventDefault();
                const resultsModalTemplate = document.getElementById("resultsModal");
                const resultsModal = resultsModalTemplate.cloneNode(true);
                const mainDiv = document.querySelector(".mainDiv");
                mainDiv.appendChild(resultsModal);
                resultsModal.id = "resultsModal_testID-" + doc.id;
                resultsModal.style.display = "flex";
                const responseJSON = doc.data().scoreRecorded;
                const wpmArray = doc.data()?.wpmArray ?? [];
                const average = array => array.reduce((a, b) => a + b) / array.length;  
                resultsModal.querySelector(".averageScore").innerText = responseJSON.average_score + "/100";
                resultsModal.querySelector(".grammarScore").innerText = responseJSON.grammar_punctuation + "/100";
                resultsModal.querySelector(".fluencyScore").innerText = responseJSON.fluency_vocabulary + "/100";
                resultsModal.querySelector(".creativityScore").innerText = responseJSON.creativity + "/100";
                resultsModal.querySelector(".eiScore").innerText = responseJSON.emotional_intelligence + "/100";
                resultsModal.querySelector(".wpmAverage").innerText = Math.round(average(wpmArray));
                resultsModal.querySelector(".wpmScore").innerText = responseJSON.wpm_score + "/100";
                resultsModal.querySelector(".internetScore").innerText = responseJSON.internet_score + "/100";
                resultsModal.querySelector(".recommendationText").innerText = responseJSON.recommendation;
                resultsModal.querySelector(".examples").innerHTML = responseJSON.examples.map((example) => { return "<div><p>" + example + "</p></div>"}).join("");
                resultsModal.querySelector(".resultsModalCloseBtn").style.display = "flex";
                resultsModal.querySelector("#openTrainers").style.display = "none";
                resultsModal.querySelector(".resultsModalCloseBtn").addEventListener("click", (e) => {
                  e.stopImmediatePropagation();
                  resultsModal.remove();
                })
                resultsModal.querySelector("#wpmTooltip").addEventListener("mouseover", async (e) => {
                  resultsModal.querySelector(".tooltip").style.display = "flex";
                });
                resultsModal.querySelector("#wpmTooltip").addEventListener("mouseleave", async (e) => {
                  resultsModal.querySelector(".tooltip").style.display = "none";
                });
              })
            } else {
              col4Btn.style.color = "#ADADAD";
              col4Btn.style.pointerEvents = "none";
            }
          }
          document.querySelector("[data-id='agencyDiv']").querySelector(".chatScreen").appendChild(newChatterView);
          document.querySelector(".loadingScreen").style.display = "none";
          document.querySelector("[data-id='agencyDiv']").querySelector(".chatScreen").style.display = "flex";
          newChatterView.style.display = "flex";
          console.log(seriesHeaders, averageScores, emotionalIntelligenceScores, fluencyVocabularyScores, creativityScores, grammarPunctuationScores, internetScores, wpmScores);
          newChatterView.querySelector(".chatterQuizGraph").id = `progressGraph${chatter}`;
          newChatterView.querySelector(".averageScoreChatter").id = `averageScoreChatter${chatter}`;
          newChatterView.querySelector(".averageWPMChatter").id = `averageWPMChatter${chatter}`;
          newChatterView.querySelector(".averageInternetScoreChatter").id = `averageInternetScoreChatter${chatter}`;
          newChatterView.querySelector(".averageFluencyVocabulary").id = `averageFluencyVocabulary${chatter}`;
          Highcharts.chart(`progressGraph${chatter}`, {
              chart: {
                  type: 'column'
              },
              title: {
                  text: 'Quiz Results Over Time',
                  align: 'center'
              },
              xAxis: {
                  categories: seriesHeaders,
                  crosshair: true,
                  title: {
                    text: 'Tests'
                  }
              },
              yAxis: {
                  min: 0,
                  max: 100,
                  title: {
                    text: 'Score'
                  }
              },
              legend: {
                  enabled: true
              },
              credits: {
                  enabled: false
              },
              plotOptions: {
                  column: {
                      pointPadding: 0.2,
                      borderWidth: 0
                  }
              },
              series: [
                  {
                      name: 'Average Score',
                      data: averageScores
                  },
                  {
                      name: 'Emotional Intelligence Score',
                      data: emotionalIntelligenceScores
                  },
                  {
                      name: 'Creativity Score',
                      data: creativityScores
                  }, 
                  {
                      name: 'Fluency & Vocabulary Score',
                      data: fluencyVocabularyScores
                  },
                  {
                      name: 'Grammar & Punctuation Score',
                      data: grammarPunctuationScores
                  },
                  {
                      name: 'Internet Score',
                      data: internetScores
                  },
                  {
                      name: 'Word Per Minute Score',
                      data: wpmScores
                  }
              ]
          });
          Highcharts.chart(`averageScoreChatter${chatter}`, {
            chart: {
              type: 'solidgauge',
              backgroundColor: "transparent",
              custom: {}
            },
            title: {
              text: 'Average Score',
              style: {
                  fontSize: '18px',
                  fontFamily: "'Montserrat', sans-serif",
                  lineHeight: "22px"
              }
            },
            legend: {
              enabled: false
            },
            credits: {
              enabled: false
            },
            yAxis: {
              min: 0,
              max: 100,
              lineWidth: 0,
              tickPositions: []
            },
            tooltip: {
              enabled: false
            },
            plotOptions: {
              solidgauge: {
                dataLabels: {
                  enabled: true,
                  borderWidth: 0,
                  backgroundColor: 'none',
                  align: "center",
                  verticalAlign: "middle",
                  shadow: false,
                  style: {
                      fontSize: '16px'
                  },
                  useHTML: true,
                  formatter: function() {
                    return `<div style="display: flex; flex-direction: column; align-items: center; justify-content: center; font-weight: bold;">${this.series.name}<br>
                      <span style="display: flex; font-size: 1.5em; color: ${this.point.color}; text-align: center; flex: 1; font-weight: bold;">${this.point.y}/100</span></div>`
                  },
                  positioner: function (labelWidth) {
                      return {
                          x: (this.chart.chartWidth - labelWidth) / 2,
                          y: (this.chart.plotHeight / 2) + 15
                      };
                  }
                },
                linecap: 'round',
                stickyTracking: false,
                rounded: true
              }
            },
            pane: {
              startAngle: 0,
              endAngle: 360,
              background: [{
                outerRadius: '110%',
                innerRadius: '90%',
                borderWidth: 0,
                backgroundColor: "rgba(37, 233, 174, 0.1)"
              }]
            },
            series: [{
              name: "Average Score",
              data: [{
                color: "#25E9AE",
                radius: '110%',
                innerRadius: "90%",
                name: 'Average Score',
                y: Math.round(average(averageScores))
              }]
            }]
          });
          Highcharts.chart(`averageWPMChatter${chatter}`, {
            chart: {
              type: 'solidgauge',
              backgroundColor: "transparent",
              custom: {}
            },
            title: {
              text: 'Average WPM',
              style: {
                  fontSize: '18px',
                  fontFamily: "'Montserrat', sans-serif",
                  lineHeight: "22px"
              }
            },
            legend: {
              enabled: false
            },
            credits: {
              enabled: false
            },
            yAxis: {
              min: 0,
              max: 90,
              lineWidth: 0,
              tickPositions: []
            },
            tooltip: {
              enabled: false
            },
            plotOptions: {
              solidgauge: {
                dataLabels: {
                  enabled: true,
                  borderWidth: 0,
                  backgroundColor: 'none',
                  align: "center",
                  verticalAlign: "middle",
                  shadow: false,
                  style: {
                      fontSize: '16px'
                  },
                  useHTML: true,
                  formatter: function() {
                    return `<div style="display: flex; flex-direction: column; align-items: center; justify-content: center; font-weight: bold;">${this.series.name}<br>
                      <span style="display: flex; font-size: 1.5em; color: ${this.point.color}; text-align: center; flex: 1; font-weight: bold;">${this.point.y} WPM</span></div>`
                  },
                  positioner: function (labelWidth) {
                      return {
                          x: (this.chart.chartWidth - labelWidth) / 2,
                          y: (this.chart.plotHeight / 2) + 15
                      };
                  }
                },
                linecap: 'round',
                stickyTracking: false,
                rounded: true
              }
            },
            pane: {
              startAngle: 0,
              endAngle: 360,
              background: [{
                outerRadius: '110%',
                innerRadius: '90%',
                borderWidth: 0,
                backgroundColor: "rgba(23, 40, 192, 0.1)"
              }]
            },
            series: [{
              name: 'Average',
              data: [{
                color: "#1728C0",
                radius: '110%',
                innerRadius: "90%",
                y: Math.round(average(wpmArray))
              }]
            }]
          });
          Highcharts.chart(`averageInternetScoreChatter${chatter}`, {
            chart: {
              type: 'solidgauge',
              backgroundColor: "transparent",
              custom: {}
            },
            title: {
              text: 'Internet Score Average',
              style: {
                  fontSize: '18px',
                  fontFamily: "'Montserrat', sans-serif",
                  lineHeight: "22px"
              }
            },
            legend: {
              enabled: false
            },
            credits: {
              enabled: false
            },
            yAxis: {
              min: 0,
              max: 100,
              lineWidth: 0,
              tickPositions: []
            },
            tooltip: {
              enabled: false
            },
            plotOptions: {
              solidgauge: {
                dataLabels: {
                  enabled: true,
                  borderWidth: 0,
                  backgroundColor: 'none',
                  align: "center",
                  verticalAlign: "middle",
                  shadow: false,
                  style: {
                      fontSize: '16px'
                  },
                  useHTML: true,
                  formatter: function() {
                    return `<div style="display: flex; flex-direction: column; align-items: center; justify-content: center; font-weight: bold;">${this.series.name}<br>
                      <span style="display: flex; font-size: 1.5em; color: ${this.point.color}; text-align: center; flex: 1; font-weight: bold;">${this.point.y}/100</span></div>`
                  },
                  positioner: function (labelWidth) {
                      return {
                          x: (this.chart.chartWidth - labelWidth) / 2,
                          y: (this.chart.plotHeight / 2) + 15
                      };
                  }
                },
                linecap: 'round',
                stickyTracking: false,
                rounded: true
              }
            },
            pane: {
              startAngle: 0,
              endAngle: 360,
              background: [{
                outerRadius: '110%',
                innerRadius: '90%',
                borderWidth: 0,
                backgroundColor: "rgba(23, 40, 192, 0.1)"
              }]
            },
            series: [{
              name: 'Average',
              data: [{
                color: "#333333",
                radius: '110%',
                innerRadius: "90%",
                y: Math.round(average(internetScores))
              }]
            }]
          });
          Highcharts.chart(`averageFluencyVocabulary${chatter}`, {
            chart: {
              type: 'solidgauge',
              backgroundColor: "transparent",
              custom: {}
            },
            title: {
              text: 'Average Fluency & Vocab Score',
              style: {
                  fontSize: '18px',
                  fontFamily: "'Montserrat', sans-serif",
                  lineHeight: "22px"
              }
            },
            legend: {
              enabled: false
            },
            credits: {
              enabled: false
            },
            yAxis: {
              min: 0,
              max: 100,
              lineWidth: 0,
              tickPositions: []
            },
            tooltip: {
              enabled: false
            },
            plotOptions: {
              solidgauge: {
                dataLabels: {
                  enabled: true,
                  borderWidth: 0,
                  backgroundColor: 'none',
                  align: "center",
                  verticalAlign: "middle",
                  shadow: false,
                  style: {
                      fontSize: '16px'
                  },
                  useHTML: true,
                  formatter: function() {
                    return `<div style="display: flex; flex-direction: column; align-items: center; justify-content: center; font-weight: bold;">${this.series.name}<br>
                      <span style="display: flex; font-size: 1.5em; color: ${this.point.color}; text-align: center; flex: 1; font-weight: bold;">${this.point.y}/100</span></div>`
                  },
                  positioner: function (labelWidth) {
                      return {
                          x: (this.chart.chartWidth - labelWidth) / 2,
                          y: (this.chart.plotHeight / 2) + 15
                      };
                  }
                },
                linecap: 'round',
                stickyTracking: false,
                rounded: true
              }
            },
            pane: {
              startAngle: 0,
              endAngle: 360,
              background: [{
                outerRadius: '110%',
                innerRadius: '90%',
                borderWidth: 0,
                backgroundColor: "rgba(23, 40, 192, 0.1)"
              }]
            },
            series: [{
              name: 'Average',
              data: [{
                color: "#E9B225",
                radius: '110%',
                innerRadius: "90%",
                y: Math.round(average(fluencyVocabularyScores))
              }]
            }]
          });
        } else {
          newChatterView.querySelector(".previousTests").querySelector(".locked").style.display = "flex";
          newChatterView.querySelector(".previousTests").querySelector(".unlocked").style.display = "none";
          newChatterView.querySelector(".assignedTests").querySelector(".locked").style.display = "flex";
          newChatterView.querySelector(".assignedTests").querySelector(".unlocked").style.display = "none";
          document.querySelector("[data-id='agencyDiv']").querySelector(".chatScreen").appendChild(newChatterView);
          document.querySelector(".loadingScreen").style.display = "none";
          document.querySelector("[data-id='agencyDiv']").querySelector(".chatScreen").style.display = "flex";
          newChatterView.style.display = "flex";
        }
      })
      document.querySelector("[data-id='screenName']").innerText = fullName;
    }
  })
}

const loadChatScreen = async (reload = false) => {
  if (window.screen.width <= 550) {
    document.querySelector(".sidebar").classList.remove("display-flex")
  } else {
    document.querySelector(".sidebar").classList.add("display-flex")
  }
  await getDoc(doc(db, "users", auth.currentUser.uid)).then(async (userDoc) => {
    if (userDoc.exists()) {
      let stage = userDoc.data().stage;
      let assignedTests = userDoc.data()?.assignedTests ?? [];
      fullName = userDoc.data().fullName;
      if (fullName.split(" ").length > 1) {
        monogram = Array.from(fullName)[0] + (Array?.from(fullName?.split(" ")?.[1])?.[0] ?? "");
      } else {
        monogram = Array.from(fullName)[0];
      }
      monogramH2.innerText = monogram;
      customerNameH3.innerText = fullName;

      if (!assignedTests.includes("proficiency_test")) {
        document.querySelector("[data-id='proficiencyTest']").classList.add("notAssigned");
        document.querySelector("[data-id='continueToProficiencyTest']").style.color = "#ADADAD";
        document.querySelector("[data-id='continueToProficiencyTest']").style.borderColor = "#ADADAD";
        document.querySelector("[data-id='continueToProficiencyTest']").style.pointerEvents = "none";
      }

      if (stage === 'intro') {
        introductionArea.style.display = "flex";
        proficiencyTestArea.style.display = "none";
        messageArea.style.display = "none";
        newConversationDisplay.style.display = "flex";
        proficiencyTestIntro.style.display = "none";
      } else if (stage === 'proficiencyTest') {
        introductionArea.style.display = "none";
        proficiencyTestArea.style.display = "flex";
        messageArea.style.display = "none";
        newConversationDisplay.style.display = "none";
        proficiencyTestIntro.style.display = "flex";
      } else if (stage === 'testing') {
        if (localStorage.getItem("testID") === null) {
          introductionArea.style.display = "none";
          proficiencyTestArea.style.display = "flex";
          messageArea.style.display = "none";
          newConversationDisplay.style.display = "none";
          proficiencyTestIntro.style.display = "flex";
        } else {
          introductionArea.style.display = "none";
          proficiencyTestArea.style.display = "none";
          messageArea.style.display = "flex";
          newConversationDisplay.style.display = "none";
          proficiencyTestIntro.style.display = "none";
          proficiencyTestScreen.style.display = 'flex';
          loadChat();      
        }
      }

    }
  })
}

window.addEventListener("DOMContentLoaded", async () => {
  setTimeout(async () => {
    let user = auth.currentUser;
    console.log(user)
    if (user !== null) {
      console.log("Signed In!")
      await getDoc(doc(db, "users", auth.currentUser.uid)).then(async (userDoc) => {
        if (userDoc.exists()) {
          const userType = await checkUserType();
          console.log(userType)
          if (userType.goToDashboard === true) {
            await checkCustomer().then(async (canAccess) => {
              if (canAccess === true) {
                await loadAgencyScreen();
                Sentry.setUser({
                  fullName: userDoc.data().fullName,
                  email: auth.currentUser.email,
                });
                await auth.currentUser.getIdToken(true).then(async (idToken) => {
                  await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/intercom/createUserHash`, {
                    method: "POST",
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer '+ idToken,
                    },
                    body: JSON.stringify({
                      firebaseUID: auth.currentUser.uid,
                    })
                  }).then((res) => res.json()).then(async (res) => {
                    Intercom({
                      app_id: 'dearsmrf',
                      user_id: auth.currentUser.uid, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
                      name: userDoc.data().fullName, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
                      email: auth.currentUser.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
                      user_hash: res.hash,
                    });
                  })
                })
                feedback.createWidget()
              } else {
                const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink')
                const { data } = await functionRef({
                  returnUrl: "https://dojo.getbotly.com",
                });
                window.open(data.url,'_blank');                
              }
            })
          } else {
            let stage = userDoc.data().stage;
            if (stage === "signed_up") {
              accountInfoScreen.style.display = "flex";
            } else if (stage === "testing") {
              await loadChat();
              Sentry.setUser({
                fullName: userDoc.data().fullName,
                email: auth.currentUser.email,
              });
              await auth.currentUser.getIdToken(true).then(async (idToken) => {
                await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/intercom/createUserHash`, {
                  method: "POST",
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ idToken,
                  },
                  body: JSON.stringify({
                    firebaseUID: auth.currentUser.uid,
                  })
                }).then((res) => res.json()).then(async (res) => {
                  Intercom({
                    app_id: 'dearsmrf',
                    user_id: auth.currentUser.uid, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
                    name: userDoc.data().fullName, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
                    email: auth.currentUser.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
                    user_hash: res.hash,
                  });
                })
              })
              feedback.createWidget()
            }
          }
        }
      })
    } else {
      console.log("Not signed in.")
      loginDiv.style.display = "flex";
      createAccountDiv.style.display = "none";      
      loginBtn.addEventListener("click", login)
      document.querySelector("[data-id='loginAgainBtn']").addEventListener("click", () => {
        loginDiv.style.display = "none";
        createAccountDiv.style.display = "flex";
      })
    }
  }, 2000)
})

const checkCustomer = async () => {
  return new Promise(async (resolve, reject) => {
    return auth.currentUser.getIdToken(true).then(async (idToken) => {
      return await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/stripe/checkCustomer`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ idToken,
        },
        body: JSON.stringify({
          firebaseUID: auth.currentUser.uid,
        })
      }).then((res) => res.json()).then(async (res) => {
        console.log(res)
        if (res.body.canAccess === true) {
          return resolve(true);
        } else {
          return reject(false);
        }
      }).catch((error) => {
        console.error(error)
      })
    })
  })
}

window.loadChat = async () => {
  if (window.location.href.toString().includes("trainers")) {
    const trainerDiv = document.querySelector("[data-id='trainerDiv']");
    trainerDiv.style.display = "flex";
    trainerDiv.querySelector("[data-id='trainerMessageText']").textContent = "";
    trainerDiv.querySelector("[data-id='trainerMessageText']").setAttribute("contenteditable", "false");
    trainerDiv.querySelector("[data-id='trainerMessageText']").pointerEvents = "none";  
    trainerDiv.querySelector("[data-id='trainerMessageText']").textContent = "";
    trainerDiv.querySelector("[data-id='trainerMessageList']").innerHTML = "";
    trainerDiv.querySelector("[data-id='trainerMessageList']").style.display = "none";
    trainerDiv.querySelector(".warningScreen").style.display = "flex";
    trainerDiv.querySelector(".messageArea").style.display = "flex";
    trainerDiv.querySelector(".trainerScreen").style.display = "flex";
    trainerDiv.querySelector(".modelInfo").style.display = "none";
    trainerDiv.querySelector(".fanInfo").style.display = "none";
    await getDoc(doc(db, "users", auth.currentUser.uid)).then(async (usersDoc) => {
      if (usersDoc.exists()) {
        fullName = usersDoc.data().fullName;
        if (fullName.split(" ").length > 1) {
          monogram = Array.from(fullName)[0] + (Array?.from(fullName?.split(" ")?.[1])?.[0] ?? "");
        } else {
          monogram = Array.from(fullName)[0];
        }
        var monogramH2 = trainerDiv.querySelector("[data-id='monogram']");
        var customerNameH3 = trainerDiv.querySelector("[data-id='customerName']");
        monogramH2.textContent = monogram;
        customerNameH3.textContent = fullName;
      }
    })
    return auth.currentUser.getIdToken(true).then(async (idToken) => {
      return await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/trainers/getTrainers`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ idToken,
        },
        body: JSON.stringify({
          firebaseUID: auth.currentUser.uid,
        })
      }).then((res) => res.json()).then(async (res) => {
        for (const trainer of Object.keys(res?.trainers)) {
          const trainerName = res?.trainers[trainer].trainerName;
          const trainerView = document.createElement("div");
          trainerView.classList.add("chatHistoryItem");
          trainerView.setAttribute("data-id", trainerName);
          trainerView.innerHTML = `
            <p>${trainerName}</p>
            <i class="fa-regular fa-circle-check" style="display: none;"></i>
          `;
          trainerDiv.querySelector(".scrollableInnerDiv").appendChild(trainerView);
          document.querySelector(`[data-id='${trainerName}']`).addEventListener("click", async (e) => {
            e.stopImmediatePropagation();
            await loadTrainer(trainerName);
          })
        }
      })
    });
  } else {
    chatterDiv.style.display = "flex";
    chatterDiv.querySelector("[data-id='messageText']").textContent = "";
    chatterDiv.querySelector("[data-id='messageList']").innerHTML = "";
    chatterDiv.querySelector(".messageArea").style.display = "flex";
    introductionArea.style.display = "none";
    proficiencyTestArea.style.display = "none";
    newConversationDisplay.style.display = "none";
    proficiencyTestIntro.style.display = "none";
    proficiencyTestScreen.style.display = 'flex';
  
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const createQuiz = urlParams.get("createQuiz");
    const average = array => array.reduce((a, b) => a + b) / array.length;
    await getDoc(doc(db, "users", auth.currentUser.uid)).then(async (usersDoc) => {
      if (usersDoc.exists()) {
        fullName = usersDoc.data().fullName;
        if (fullName.split(" ").length > 1) {
          monogram = Array.from(fullName)[0] + (Array?.from(fullName?.split(" ")?.[1])?.[0] ?? "");
        } else {
          monogram = Array.from(fullName)[0];
        }
        var monogramH2 = chatterDiv.querySelector("[data-id='monogram']");
        var customerNameH3 = chatterDiv.querySelector("[data-id='customerName']");
        monogramH2.textContent = monogram;
        customerNameH3.textContent = fullName;
      }
    })
  
    if (createQuiz === "true") {
      localStorage.removeItem("testID")
      await getDoc(doc(db, "users", auth.currentUser.uid)).then(async (userDoc) => {
        if (userDoc.exists()) {
          let completedSurvey = userDoc.data()?.completedSurvey;
          if (completedSurvey !== true) {
            document.querySelector("#betaSurveyModal").style.display = "flex";
          }
        }
      })
    }
  
    if (localStorage.getItem("testID") === null || localStorage.getItem("testID") === "undefined") {
      await getDocs(collection(db, "users", auth.currentUser.uid, "tests"), orderBy("metadata.createdAt", "desc")).then(async (testDocs) => {
        if (testDocs.docs.length > 0 && createQuiz !== "true") {
          localStorage.setItem("testID", testDocs.docs[0].id);
          await getDoc(doc(db, "users", auth.currentUser.uid, "tests", testDocs.docs[0].id)).then((testDoc) => {
            if (testDoc.exists()) {
              let questionNumber = testDoc.data().metadata.questionNumber;
              let questionResponses = testDoc.data()?.questionResponses ?? [];
              currentQuestionNumber = questionNumber;
              if (currentQuestionNumber >= 0 && currentQuestionNumber < 2) {
                chatterDiv.querySelector(".modelInfo").style.display = "flex";
                chatterDiv.querySelector(".fanInfo").style.display = "flex";
              } else {
                chatterDiv.querySelector(".modelInfo").style.display = "none";
                chatterDiv.querySelector(".fanInfo").style.display = "none";
              }
              if (questionResponses.length > 0) {
                document.getElementById("scenarioProgress").innerText = questionResponses.length >= 4 ? "100%" : Math.round((questionResponses.length / 4) * 100) + "%";
                document.getElementById("basicProgress").innerText = questionResponses.length >= 4 && questionResponses.length >= 9 ? "100%" : (questionResponses.length > 4 ? Math.round(((questionResponses.length - 5)/ 5) * 100) + "%" : "0%");
                document.getElementById("advancedProgress").innerText = questionResponses.length >= 9 && questionResponses.length >= 14 ? "100%" : (questionResponses.length > 9 ? Math.round(((questionResponses.length - 10) / 5) * 100) + "%" : "0%");
                document.querySelector(`#progressPercentage`).innerText = Math.round((questionResponses.length / quizQuestions.length) * 100) + "%";
              }
              for (var i = 0; i < questionResponses.length; i++) {
                console.log(i);
                document.querySelector(`[data-questionNumber="${i}"]`).classList.add("completed");
                if (i >= 0 && i < 3) {
                  chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                    <div class="messageBox ai">
                      <div class="messageBoxRow">
                        <div class="monogram john">
                        </div>
                        <p class="messageText" data-id="quiz-question${i}">${quizQuestions[i]}</p>
                      </div>
                    </div>`                   
                } else {
                  chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                    <div class="messageBox ai">
                      <div class="messageBoxRow">
                        <div class="monogram botly">
                        </div>
                        <p class="messageText" data-id="quiz-question${i}">${quizQuestions[i]}</p>
                      </div>
                    </div>`                  
                }
                chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                <div class="messageBox ai">
                  <div class="messageBoxRow">
                    <div class="monogram">
                      <h2 class="messageMonogram">${monogram}</h2>
                    </div>
                    <p class="messageText" data-id="questionResponse-${i}">${questionResponses[i]}</p>
                  </div>
                </div>`;
              }
              wpmArray = testDoc.data()?.wpmArray ?? [];
              if (questionResponses.length === quizQuestions.length) {
                const resultsModalTemplate = document.getElementById("resultsModal");
                const resultsModal = resultsModalTemplate.cloneNode(true);
                const mainDiv = document.querySelector(".mainDiv");
                mainDiv.appendChild(resultsModal);
                resultsModal.id = "resultsModal_testID-" + localStorage.getItem("testID");
                resultsModal.style.display = "flex";
                let speedtestResults = testDoc.data().speedtestResults;
                const apiBody = JSON.stringify({
                  history: questionResponses,
                  wpmAverage: Math.round(average(wpmArray)),
                  quizQuestions: quizQuestions,
                  speedtestResults: speedtestResults
                });
                messageText.textContent = "";
                newConversationDisplay.style.display = "none";
                // suggestionsDiv.style.display = "none";
                chatterDiv.querySelector("[data-id='messageList']").style.display = "flex";
                const string = apiBody + "33746c42427246695962593976455255717a656777766f395152696857676e69";
                const bodyEncrypted = crypto.createHash("md5").update(string).digest("hex");
                console.log(string)
                console.log(bodyEncrypted);
                resultsModal.querySelector(".averageScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                resultsModal.querySelector(".grammarScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                resultsModal.querySelector(".fluencyScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                resultsModal.querySelector(".creativityScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                resultsModal.querySelector(".eiScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                resultsModal.querySelector(".wpmAverage").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                resultsModal.querySelector(".wpmScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                resultsModal.querySelector(".internetScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                resultsModal.querySelector(".recommendationText").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                resultsModal.querySelector(".examples").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                resultsModal.querySelector("#wpmTooltip").addEventListener("mouseover", async (e) => {
                  resultsModal.querySelector(".tooltip").style.display = "flex";
                });
                resultsModal.querySelector("#wpmTooltip").addEventListener("mouseleave", async (e) => {
                  resultsModal.querySelector(".tooltip").style.display = "none";
                });
                if (testDoc.data()?.scoreRecorded !== undefined) {
                  const responseJSON = testDoc.data().scoreRecorded;
                  resultsModal.querySelector(".averageScore").innerText = responseJSON.average_score + "/100";
                  resultsModal.querySelector(".grammarScore").innerText = responseJSON.grammar_punctuation + "/100";
                  resultsModal.querySelector(".fluencyScore").innerText = responseJSON.fluency_vocabulary + "/100";
                  resultsModal.querySelector(".creativityScore").innerText = responseJSON.creativity + "/100";
                  resultsModal.querySelector(".eiScore").innerText = responseJSON.emotional_intelligence + "/100";
                  resultsModal.querySelector(".wpmAverage").innerText = Math.round(average(wpmArray));
                  resultsModal.querySelector(".wpmScore").innerText = responseJSON.wpm_score + "/100";
                  resultsModal.querySelector(".internetScore").innerText = responseJSON.internet_score + "/100";
                  resultsModal.querySelector(".recommendationText").innerText = responseJSON.recommendation;
                  resultsModal.querySelector(".examples").innerHTML = responseJSON.examples.map((example) => { return "<div><p>" + example + "</p></div>"}).join("");
                } else {
                  auth.currentUser.getIdToken(true).then(async (idToken) => {
                    const apiHeaders = {
                      headers: {
                          "Authorization": `Bearer ${idToken}`,
                          "X-Pingback": bodyEncrypted,
                      }
                    }
                    let url = `${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/messages/checkProficiency/`;
                    await axios.post(url, apiBody, apiHeaders).then(async (response) => {
                      console.log(response.data)
                      const responseJSON = JSON.parse(response.data.response);
                      console.log(responseJSON)
                      // resultsModal.querySelector(".resultsModalCloseBtn").style.display = "none";
                      resultsModal.querySelector(".averageScore").innerText = responseJSON.average_score + "/100";
                      resultsModal.querySelector(".grammarScore").innerText = responseJSON.grammar_punctuation + "/100";
                      resultsModal.querySelector(".fluencyScore").innerText = responseJSON.fluency_vocabulary + "/100";
                      resultsModal.querySelector(".creativityScore").innerText = responseJSON.creativity + "/100";
                      resultsModal.querySelector(".eiScore").innerText = responseJSON.emotional_intelligence + "/100";
                      resultsModal.querySelector(".wpmAverage").innerText = Math.round(average(wpmArray));
                      resultsModal.querySelector(".wpmScore").innerText = responseJSON.wpm_score + "/100";
                      resultsModal.querySelector(".internetScore").innerText = responseJSON.internet_score + "/100";
                      resultsModal.querySelector(".recommendationText").innerText = responseJSON.recommendation;
                      resultsModal.querySelector(".examples").innerHTML = responseJSON.examples.map((example) => { return "<div><p>" + example + "</p></div>"}).join("");
                      await setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
                        dateCompleted: new Date(),
                        scoreRecorded: responseJSON
                      }, { merge: true })
                    })
                  })
                }
              } else {
                document.querySelector(`[data-questionNumber="${questionNumber}"]`).classList.add("selected");
                if (currentQuestionNumber >= 0 && currentQuestionNumber < 2) {
                  chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                    <div class="messageBox ai">
                      <div class="messageBoxRow">
                        <div class="monogram john">
                        </div>
                        <p class="messageText" data-id="quiz-question${currentQuestionNumber}">${quizQuestions[currentQuestionNumber]}</p>
                      </div>
                    </div>`                   
                } else {
                  chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                    <div class="messageBox ai">
                      <div class="messageBoxRow">
                        <div class="monogram botly">
                        </div>
                        <p class="messageText" data-id="quiz-question${currentQuestionNumber}">${quizQuestions[currentQuestionNumber]}</p>
                      </div>
                    </div>`                  
                }
              }
              const sidebarDiv = $(`.scrollableInnerDiv`);
              sidebarDiv.animate({
                  scrollTop: sidebarDiv.scrollTop() - sidebarDiv.offset().top + $(`[data-questionNumber="${currentQuestionNumber}"]`).offset().top - 32
              }, 500);
              const speedTest = new SpeedTest({
                turnServerUser: "10d4751126891cd6c6afe03c7b0588c017932abae7cd2f1955db057671c0a1033809f034c1aca94adc213009cd265a0f9110336f3e1d405c7f48ea86469fb8c3",
                turnServerPass: "aba9b169546eb6dcc7bfb1cdf34544cf95b5161d602e3b5fa7c8342b2e9802fb"
              })
              speedTest.onFinish = async (result) => {
                let summary = result.getSummary();
                let scores = result.getScores();
                let latency = summary.latency;
                let downloadInBits = summary.download;
                let uploadInBits = summary.upload;
                let rtcScore = scores["rtc"].classificationName;
                // const speedTestResults = document.getElementById("speedTestResults");
                // const downloadResult = document.getElementById("downloadResult");
                // const uploadResult = document.getElementById("uploadResult");
                // const pingResult = document.getElementById("pingResult");
                // const ratingResult = document.getElementById("ratingResult");
                // console.log(summary);
                // pingResult.innerText = Math.round(latency) + " ms";
                // ratingResult.innerText = rtcScore;
                // downloadResult.innerText = Math.round(downloadInBits / 1048576) + " Mbps";
                // uploadResult.innerText = Math.round(uploadInBits / 1048576) + " Mbps";
                // speedTestResults.style.display = "flex";
                await setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
                  testStarted: new Date(),
                  speedtestResults: {
                    download: Math.round(downloadInBits / 1048576),
                    upload: Math.round(uploadInBits / 1048576),
                    pingResult: Math.round(latency),
                    ratingResult: rtcScore,
                  }
                }, {merge: true})
                if (latency < 20) {
                  pingResult.style.color = "green";
                } else if (latency < 50 && latency >= 20) {
                  pingResult.style.color = "orange";
                } else if (latency >= 50) {
                  pingResult.style.color = "red";
                }
                if (Math.round(downloadInBits / 1048576) < 10) {
                  downloadResult.style.color = "red";
                } else if (Math.round(downloadInBits / 1048576) < 50 && Math.round(downloadInBits / 1048576) >= 10) {
                  downloadResult.style.color = "orange";
                } else if (Math.round(downloadInBits / 1048576) >= 50) {
                  downloadResult.style.color = "green";
                }
                if (Math.round(uploadInBits / 1048576) < 10) {
                  uploadResult.style.color = "red";
                } else if (Math.round(uploadInBits / 1048576) < 30 && Math.round(uploadInBits / 1048576) >= 10) {
                  uploadResult.style.color = "orange";
                } else if (Math.round(uploadInBits / 1048576) >= 30) {
                  uploadResult.style.color = "green";
                }
                if (rtcScore === "poor") {
                  ratingResult.style.color = "red";
                } else if (rtcScore === "average") {
                  ratingResult.style.color = "orange";
                } else if (rtcScore === "good") {
                  ratingResult.style.color = "green";
                }
              }
              let messagesArray = testDoc.data()?.messagesArray ?? [];
              let wpmArrayDb = testDoc.data()?.wpmArray ?? [];
              wpmArray = wpmArrayDb;
              // document.getElementById("liveWPM").innerText = wpmArray.length > 0 ? Math.round(average(wpmArray)) + " WPM" : "0 WPM";
              const chatHistoryDiv = $(`[data-id='messageList']`);
              chatHistoryDiv.animate({
                  scrollTop: chatHistoryDiv.prop("scrollHeight")
              }, 500);
              if (wpmArray.length > 0) {
                if (Math.round(average(wpmArray)) > 100) {
                  document.querySelector(".copyPasteDetected").style.display = "flex";
                  messageText.textContent = "";
                  messageText.setAttribute("contenteditable", "false");
                  messageText.pointerEvents = "none";
                  setDoc(doc(db, "users", auth.currentUser.uid, "trainers", localStorage.getItem("trainerID")), {
                    metadata: {
                      status: "failed",
                      copyPasteDetected: true,
                    }
                  }, { merge: true })
                  return;
                }
              } else if (testDoc.data().metadata.status === "failed") {
                document.querySelector(".copyPasteDetected").style.display = "flex";
                messageText.textContent = "";
                messageText.setAttribute("contenteditable", "false");
                messageText.pointerEvents = "none";
                setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
                  metadata: {
                    status: "failed",
                    copyPasteDetected: true,
                  }
                }, { merge: true })
                return;
              }
            }
          })  
        } else {
          if (createQuiz === true) {
            urlParams.delete("createQuiz");
          }
          await auth.currentUser.getIdToken(true).then(async (idToken) => {
            await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/quizzes/createQuiz`, {
              method: "POST",
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ idToken,
              },
              body: JSON.stringify({
                firebaseUID: auth.currentUser.uid
              })
            }).then((res) => res.json()).then(async (data) => {
              console.log(data);
              localStorage.setItem("testID", data.testId);
              logEvent(analytics, "quiz_started");
              await getDoc(doc(db, "users", auth.currentUser.uid, "tests", data.testId.toString())).then((testDoc) => {
                if (testDoc.exists()) {
                  let questionNumber = testDoc.data().metadata.questionNumber;
                  currentQuestionNumber = questionNumber;
                  if (currentQuestionNumber >= 0 && currentQuestionNumber < 2) {
                    chatterDiv.querySelector(".modelInfo").style.display = "flex";
                    chatterDiv.querySelector(".fanInfo").style.display = "flex";
                  } else {
                    chatterDiv.querySelector(".modelInfo").style.display = "none";
                    chatterDiv.querySelector(".fanInfo").style.display = "none";
                  }
                  let questionResponses = testDoc?.data()?.questionResponses ?? [];
                  currentQuestionNumber = questionNumber;
                  if (questionResponses.length > 0) {
                    document.getElementById("scenarioProgress").innerText = questionResponses.length >= 4 ? "100%" : Math.round((questionResponses.length / 4) * 100) + "%";
                    document.getElementById("basicProgress").innerText = questionResponses.length >= 4 && questionResponses.length >= 9 ? "100%" : (questionResponses.length > 4 ? Math.round(((questionResponses.length - 5)/ 5) * 100) + "%" : "0%");
                    document.getElementById("advancedProgress").innerText = questionResponses.length >= 9 && questionResponses.length >= 14 ? "100%" : (questionResponses.length > 9 ? Math.round(((questionResponses.length - 10) / 5) * 100) + "%" : "0%");
                    document.querySelector(`#progressPercentage`).innerText = Math.round((questionResponses.length / quizQuestions.length) * 100) + "%";
                  }
                  for (var i = 0; i < questionResponses.length; i++) {
                    console.log(i);
                    document.querySelector(`[data-questionNumber="${i}"]`).classList.add("completed");
                    if (i >= 0 && i < 3) {
                      chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                        <div class="messageBox ai">
                          <div class="messageBoxRow">
                            <div class="monogram john">
                            </div>
                            <p class="messageText" data-id="quiz-question${i}">${quizQuestions[i]}</p>
                          </div>
                        </div>`                   
                    } else {
                      chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                        <div class="messageBox ai">
                          <div class="messageBoxRow">
                            <div class="monogram botly">
                            </div>
                            <p class="messageText" data-id="quiz-question${i}">${quizQuestions[i]}</p>
                          </div>
                        </div>`                  
                    }
                    chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                    <div class="messageBox ai">
                      <div class="messageBoxRow">
                        <div class="monogram">
                          <h2 class="messageMonogram">${monogram}</h2>
                        </div>
                        <p class="messageText" data-id="questionResponse-${i}">${questionResponses[i]}</p>
                      </div>
                    </div>`;
                  }
                  wpmArray = testDoc.data()?.wpmArray ?? [];
                  if (questionResponses.length === quizQuestions.length) {
                    const resultsModalTemplate = document.getElementById("resultsModal");
                    const resultsModal = resultsModalTemplate.cloneNode(true);
                    const mainDiv = document.querySelector(".mainDiv");
                    mainDiv.appendChild(resultsModal);
                    resultsModal.id = "resultsModal_testID-" + localStorage.getItem("testID");
                    resultsModal.style.display = "flex";
                    let speedtestResults = testDoc.data().speedtestResults;
                    const apiBody = JSON.stringify({
                      history: messagesArray,
                      wpmAverage: Math.round(average(wpmArray)),
                      quizQuestions: quizQuestions,
                      speedtestResults: speedtestResults
                    });
                    messageText.textContent = "";
                    newConversationDisplay.style.display = "none";
                    // suggestionsDiv.style.display = "none";
                    chatterDiv.querySelector("[data-id='messageList']").style.display = "flex";
                    const string = apiBody + "33746c42427246695962593976455255717a656777766f395152696857676e69";
                    const bodyEncrypted = crypto.createHash("md5").update(string).digest("hex");
                    console.log(string)
                    console.log(bodyEncrypted);
                    resultsModal.querySelector(".averageScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector(".grammarScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector(".fluencyScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector(".creativityScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector(".eiScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector(".wpmAverage").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector(".wpmScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector(".internetScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector(".recommendationText").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector(".examples").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector("#wpmTooltip").addEventListener("mouseover", async (e) => {
                      resultsModal.querySelector(".tooltip").style.display = "flex";
                    });
                    resultsModal.querySelector("#wpmTooltip").addEventListener("mouseleave", async (e) => {
                      resultsModal.querySelector(".tooltip").style.display = "none";
                    });
                    if (testDoc.data()?.scoreRecorded !== undefined) {
                      const responseJSON = testDoc.data().scoreRecorded; 
                      resultsModal.querySelector(".averageScore").innerText = responseJSON.average_score + "/100";
                      resultsModal.querySelector(".grammarScore").innerText = responseJSON.grammar_punctuation + "/100";
                      resultsModal.querySelector(".fluencyScore").innerText = responseJSON.fluency_vocabulary + "/100";
                      resultsModal.querySelector(".creativityScore").innerText = responseJSON.creativity + "/100";
                      resultsModal.querySelector(".eiScore").innerText = responseJSON.emotional_intelligence + "/100";
                      resultsModal.querySelector(".wpmAverage").innerText = Math.round(average(wpmArray));
                      resultsModal.querySelector(".wpmScore").innerText = responseJSON.wpm_score + "/100";
                      resultsModal.querySelector(".internetScore").innerText = responseJSON.internet_score + "/100";
                      resultsModal.querySelector(".recommendationText").innerText = responseJSON.recommendation;
                      resultsModal.querySelector(".examples").innerHTML = responseJSON.examples.map((example) => { return "<div><p>" + example + "</p></div>"}).join("");
                    } else {
                      auth.currentUser.getIdToken(true).then(async (idToken) => {
                        const apiHeaders = {
                          headers: {
                              "Authorization": `Bearer ${idToken}`,
                              "X-Pingback": bodyEncrypted,
                          }
                        }
                        let url = `${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/messages/checkProficiency/`;
                        await axios.post(url, apiBody, apiHeaders).then(async (response) => {
                          console.log(response.data)
                          const responseJSON = JSON.parse(response.data.response);
                          console.log(responseJSON)
                          // resultsModal.querySelector(".resultsModalCloseBtn").style.display = "none";
                          resultsModal.querySelector(".averageScore").innerText = responseJSON.average_score + "/100";
                          resultsModal.querySelector(".grammarScore").innerText = responseJSON.grammar_punctuation + "/100";
                          resultsModal.querySelector(".fluencyScore").innerText = responseJSON.fluency_vocabulary + "/100";
                          resultsModal.querySelector(".creativityScore").innerText = responseJSON.creativity + "/100";
                          resultsModal.querySelector(".eiScore").innerText = responseJSON.emotional_intelligence + "/100";
                          resultsModal.querySelector(".wpmAverage").innerText = Math.round(average(wpmArray));
                          resultsModal.querySelector(".wpmScore").innerText = responseJSON.wpm_score + "/100";
                          resultsModal.querySelector(".internetScore").innerText = responseJSON.internet_score + "/100";
                          resultsModal.querySelector(".recommendationText").innerText = responseJSON.recommendation;
                          resultsModal.querySelector(".examples").innerHTML = responseJSON.examples.map((example) => { return "<div><p>" + example + "</p></div>"}).join("");
                          await setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
                            dateCompleted: new Date(),
                            scoreRecorded: responseJSON
                          }, { merge: true })
                        })
                      })
                    }
                  } else {
                    document.querySelector(`[data-questionNumber="${questionNumber}"]`).classList.add("selected");
                    if (currentQuestionNumber >= 0 && currentQuestionNumber < 2) {
                      chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                        <div class="messageBox ai">
                          <div class="messageBoxRow">
                            <div class="monogram john">
                            </div>
                            <p class="messageText" data-id="quiz-question${currentQuestionNumber}">${quizQuestions[currentQuestionNumber]}</p>
                          </div>
                        </div>`                   
                    } else {
                      chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                        <div class="messageBox ai">
                          <div class="messageBoxRow">
                            <div class="monogram botly">
                            </div>
                            <p class="messageText" data-id="quiz-question${currentQuestionNumber}">${quizQuestions[currentQuestionNumber]}</p>
                          </div>
                        </div>`                  
                    }
                  }
                  const sidebarDiv = $(`.scrollableInnerDiv`);
                  sidebarDiv.animate({
                      scrollTop: sidebarDiv.scrollTop() - sidebarDiv.offset().top + $(`[data-questionNumber="${currentQuestionNumber}"]`).offset().top - 32
                  }, 500);
                  const speedTest = new SpeedTest({
                    turnServerUser: "10d4751126891cd6c6afe03c7b0588c017932abae7cd2f1955db057671c0a1033809f034c1aca94adc213009cd265a0f9110336f3e1d405c7f48ea86469fb8c3",
                    turnServerPass: "aba9b169546eb6dcc7bfb1cdf34544cf95b5161d602e3b5fa7c8342b2e9802fb"
                  })
                  speedTest.onFinish = async (result) => {
                    let summary = result.getSummary();
                    let scores = result.getScores();
                    let latency = summary.latency;
                    let downloadInBits = summary.download;
                    let uploadInBits = summary.upload;
                    let rtcScore = scores["rtc"].classificationName;
                    // const speedTestResults = document.getElementById("speedTestResults");
                    // const downloadResult = document.getElementById("downloadResult");
                    // const uploadResult = document.getElementById("uploadResult");
                    // const pingResult = document.getElementById("pingResult");
                    // const ratingResult = document.getElementById("ratingResult");
                    // console.log(summary);
                    // pingResult.innerText = Math.round(latency) + " ms";
                    // ratingResult.innerText = rtcScore;
                    // downloadResult.innerText = Math.round(downloadInBits / 1048576) + " Mbps";
                    // uploadResult.innerText = Math.round(uploadInBits / 1048576) + " Mbps";
                    // speedTestResults.style.display = "flex";
                    await setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
                      testStarted: new Date(),
                      speedtestResults: {
                        download: Math.round(downloadInBits / 1048576),
                        upload: Math.round(uploadInBits / 1048576),
                        pingResult: Math.round(latency),
                        ratingResult: rtcScore,
                      }
                    }, {merge: true})
                    // if (latency < 20) {
                    //   pingResult.style.color = "green";
                    // } else if (latency < 50 && latency >= 20) {
                    //   pingResult.style.color = "orange";
                    // } else if (latency >= 50) {
                    //   pingResult.style.color = "red";
                    // }
                    // if (Math.round(downloadInBits / 1048576) < 10) {
                    //   downloadResult.style.color = "red";
                    // } else if (Math.round(downloadInBits / 1048576) < 50 && Math.round(downloadInBits / 1048576) >= 10) {
                    //   downloadResult.style.color = "orange";
                    // } else if (Math.round(downloadInBits / 1048576) >= 50) {
                    //   downloadResult.style.color = "green";
                    // }
                    // if (Math.round(uploadInBits / 1048576) < 10) {
                    //   uploadResult.style.color = "red";
                    // } else if (Math.round(uploadInBits / 1048576) < 30 && Math.round(uploadInBits / 1048576) >= 10) {
                    //   uploadResult.style.color = "orange";
                    // } else if (Math.round(uploadInBits / 1048576) >= 30) {
                    //   uploadResult.style.color = "green";
                    // }
                    // if (rtcScore === "poor") {
                    //   ratingResult.style.color = "red";
                    // } else if (rtcScore === "average") {
                    //   ratingResult.style.color = "orange";
                    // } else if (rtcScore === "good") {
                    //   ratingResult.style.color = "green";
                    // }
                  }
                  let messagesArray = testDoc.data()?.messagesArray ?? [];
                  let wpmArrayDb = testDoc.data()?.wpmArray ?? [];
                  wpmArray = wpmArrayDb;
                  // document.getElementById("liveWPM").innerText = wpmArray.length > 0 ? Math.round(average(wpmArray)) + " WPM" : "0 WPM";
                  const chatHistoryDiv = $(`[data-id='messageList']`);
                  chatHistoryDiv.animate({
                      scrollTop: chatHistoryDiv.prop("scrollHeight")
                  }, 500);
                  if (wpmArray.length > 0) {
                    if (Math.round(average(wpmArray)) > 100) {
                      document.querySelector(".copyPasteDetected").style.display = "flex";
                      messageText.textContent = "";
                      messageText.setAttribute("contenteditable", "false");
                      messageText.pointerEvents = "none";
                      setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
                        metadata: {
                          status: "failed",
                          copyPasteDetected: true,
                        }
                      }, { merge: true })
                      return;
                    }
                  } else if (testDoc.data().metadata.status === "failed") {
                    document.querySelector(".copyPasteDetected").style.display = "flex";
                    messageText.textContent = "";
                    messageText.setAttribute("contenteditable", "false");
                    messageText.pointerEvents = "none";
                    setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
                      metadata: {
                        status: "failed",
                        copyPasteDetected: true,
                      }
                    }, { merge: true })
                    return;
                  }
                }
              }).catch((error) => {
                console.log(error)
              })
            })
          })
        }
      })
    } else {
      console.log(localStorage.getItem("testID"))
      await getDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID"))).then(async (testDoc) => {
        if (testDoc.exists()) {
          let questionNumber = testDoc.data().metadata.questionNumber;
          let questionResponses = testDoc.data()?.questionResponses ?? [];
          wpmArray = testDoc.data()?.wpmArray ?? [];
          currentQuestionNumber = questionNumber;
          if (currentQuestionNumber >= 0 && currentQuestionNumber < 2) {
            chatterDiv.querySelector(".modelInfo").style.display = "flex";
            chatterDiv.querySelector(".fanInfo").style.display = "flex";
          } else {
            chatterDiv.querySelector(".modelInfo").style.display = "none";
            chatterDiv.querySelector(".fanInfo").style.display = "none";
          }
          if (questionResponses.length > 0) {
            document.getElementById("scenarioProgress").innerText = questionResponses.length >= 4 ? "100%" : Math.round((questionResponses.length / 4) * 100) + "%";
            document.getElementById("basicProgress").innerText = questionResponses.length >= 4 && questionResponses.length >= 9 ? "100%" : (questionResponses.length > 4 ? Math.round(((questionResponses.length - 5)/ 5) * 100) + "%" : "0%");
            document.getElementById("advancedProgress").innerText = questionResponses.length >= 9 && questionResponses.length >= 14 ? "100%" : (questionResponses.length > 9 ? Math.round(((questionResponses.length - 10) / 5) * 100) + "%" : "0%");
            document.querySelector(`#progressPercentage`).innerText = Math.round((questionResponses.length / quizQuestions.length) * 100) + "%";
          }
          for (var i = 0; i < questionResponses.length; i++) {
            console.log(i);
            document.querySelector(`[data-questionNumber="${i}"]`).classList.add("completed");
            if (i >= 0 && i < 3) {
              chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                <div class="messageBox ai">
                  <div class="messageBoxRow">
                    <div class="monogram john">
                    </div>
                    <p class="messageText" data-id="quiz-question${i}">${quizQuestions[i]}</p>
                  </div>
                </div>`                   
            } else {
              chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                <div class="messageBox ai">
                  <div class="messageBoxRow">
                    <div class="monogram botly">
                    </div>
                    <p class="messageText" data-id="quiz-question${i}">${quizQuestions[i]}</p>
                  </div>
                </div>`                  
            }
            chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
            <div class="messageBox ai">
              <div class="messageBoxRow">
                <div class="monogram">
                    <h2 class="messageMonogram">${monogram}</h2>
                </div>
                <p class="messageText" data-id="questionResponse-${i}">${questionResponses[i]}</p>
              </div>
            </div>`;
          }
          if (questionResponses.length === quizQuestions.length) {
            const resultsModalTemplate = document.getElementById("resultsModal");
            const resultsModal = resultsModalTemplate.cloneNode(true);
            const mainDiv = document.querySelector(".mainDiv");
            mainDiv.appendChild(resultsModal);
            resultsModal.id = "resultsModal_testID-" + localStorage.getItem("testID");
            resultsModal.style.display = "flex";
            let speedtestResults = testDoc.data().speedtestResults;
            const apiBody = JSON.stringify({
              history: questionResponses,
              wpmAverage: Math.round(average(wpmArray)),
              quizQuestions: quizQuestions,
              speedtestResults: speedtestResults
            });
            messageText.textContent = "";
            newConversationDisplay.style.display = "none";
            // suggestionsDiv.style.display = "none";
            chatterDiv.querySelector("[data-id='messageList']").style.display = "flex";
            const string = apiBody + "33746c42427246695962593976455255717a656777766f395152696857676e69";
            const bodyEncrypted = crypto.createHash("md5").update(string).digest("hex");
            console.log(string)
            console.log(bodyEncrypted);
            resultsModal.querySelector(".averageScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
            resultsModal.querySelector(".grammarScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
            resultsModal.querySelector(".fluencyScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
            resultsModal.querySelector(".creativityScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
            resultsModal.querySelector(".eiScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
            resultsModal.querySelector(".wpmAverage").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
            resultsModal.querySelector(".wpmScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
            resultsModal.querySelector(".internetScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
            resultsModal.querySelector(".recommendationText").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
            resultsModal.querySelector(".examples").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
            resultsModal.querySelector("#wpmTooltip").addEventListener("mouseover", async (e) => {
              resultsModal.querySelector(".tooltip").style.display = "flex";
            });
            resultsModal.querySelector("#wpmTooltip").addEventListener("mouseleave", async (e) => {
              resultsModal.querySelector(".tooltip").style.display = "none";
            });
            if (testDoc.data()?.scoreRecorded !== undefined) {
              const responseJSON = testDoc.data().scoreRecorded;
              resultsModal.querySelector(".averageScore").innerText = responseJSON.average_score + "/100";
              resultsModal.querySelector(".grammarScore").innerText = responseJSON.grammar_punctuation + "/100";
              resultsModal.querySelector(".fluencyScore").innerText = responseJSON.fluency_vocabulary + "/100";
              resultsModal.querySelector(".creativityScore").innerText = responseJSON.creativity + "/100";
              resultsModal.querySelector(".eiScore").innerText = responseJSON.emotional_intelligence + "/100";
              resultsModal.querySelector(".wpmAverage").innerText = Math.round(average(wpmArray));
              resultsModal.querySelector(".wpmScore").innerText = responseJSON.wpm_score + "/100";
              resultsModal.querySelector(".internetScore").innerText = responseJSON.internet_score + "/100";
              resultsModal.querySelector(".recommendationText").innerText = responseJSON.recommendation;
              resultsModal.querySelector(".examples").innerHTML = responseJSON.examples.map((example) => { return "<div><p>" + example + "</p></div>"}).join("");
            } else {
              auth.currentUser.getIdToken(true).then(async (idToken) => {
                const apiHeaders = {
                  headers: {
                      "Authorization": `Bearer ${idToken}`,
                      "X-Pingback": bodyEncrypted,
                  }
                }
                let url = `${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/messages/checkProficiency/`;
                await axios.post(url, apiBody, apiHeaders).then(async (response) => {
                  console.log(response.data)
                  const responseJSON = JSON.parse(response.data.response);
                  console.log(responseJSON)
                  // resultsModal.querySelector(".resultsModalCloseBtn").style.display = "none";
                  resultsModal.querySelector(".averageScore").innerText = responseJSON.average_score + "/100";
                  resultsModal.querySelector(".grammarScore").innerText = responseJSON.grammar_punctuation + "/100";
                  resultsModal.querySelector(".fluencyScore").innerText = responseJSON.fluency_vocabulary + "/100";
                  resultsModal.querySelector(".creativityScore").innerText = responseJSON.creativity + "/100";
                  resultsModal.querySelector(".eiScore").innerText = responseJSON.emotional_intelligence + "/100";
                  resultsModal.querySelector(".wpmAverage").innerText = Math.round(average(wpmArray));
                  resultsModal.querySelector(".wpmScore").innerText = responseJSON.wpm_score + "/100";
                  resultsModal.querySelector(".internetScore").innerText = responseJSON.internet_score + "/100";
                  resultsModal.querySelector(".recommendationText").innerText = responseJSON.recommendation;
                  resultsModal.querySelector(".examples").innerHTML = responseJSON.examples.map((example) => { return "<div><p>" + example + "</p></div>"}).join("");
                  await setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
                    dateCompleted: new Date(),
                    scoreRecorded: responseJSON
                  }, { merge: true })
                })
              })
            }
          } else {
            document.querySelector(`[data-questionNumber="${questionNumber}"]`).classList.add("selected");
            if (currentQuestionNumber >= 0 && currentQuestionNumber < 2) {
              chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                <div class="messageBox ai">
                  <div class="messageBoxRow">
                    <div class="monogram john">
                    </div>
                    <p class="messageText" data-id="quiz-question${currentQuestionNumber}">${quizQuestions[currentQuestionNumber]}</p>
                  </div>
                </div>`                   
            } else {
              chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                <div class="messageBox ai">
                  <div class="messageBoxRow">
                    <div class="monogram botly">
                    </div>
                    <p class="messageText" data-id="quiz-question${currentQuestionNumber}">${quizQuestions[currentQuestionNumber]}</p>
                  </div>
                </div>`                  
            }
          }
          const sidebarDiv = $(`.scrollableInnerDiv`);
          sidebarDiv.animate({
              scrollTop: sidebarDiv.scrollTop() - sidebarDiv.offset().top + $(`[data-questionNumber="${currentQuestionNumber}"]`).offset().top - 32
          }, 500);
          const speedTest = new SpeedTest({
            turnServerUser: "10d4751126891cd6c6afe03c7b0588c017932abae7cd2f1955db057671c0a1033809f034c1aca94adc213009cd265a0f9110336f3e1d405c7f48ea86469fb8c3",
            turnServerPass: "aba9b169546eb6dcc7bfb1cdf34544cf95b5161d602e3b5fa7c8342b2e9802fb"
          })
          speedTest.onFinish = async (result) => {
            let summary = result.getSummary();
            let scores = result.getScores();
            let latency = summary.latency;
            let downloadInBits = summary.download;
            let uploadInBits = summary.upload;
            let rtcScore = scores["rtc"].classificationName;
            // const speedTestResults = document.getElementById("speedTestResults");
            // const downloadResult = document.getElementById("downloadResult");
            // const uploadResult = document.getElementById("uploadResult");
            // const pingResult = document.getElementById("pingResult");
            // const ratingResult = document.getElementById("ratingResult");
            // console.log(summary);
            // pingResult.innerText = Math.round(latency) + " ms";
            // ratingResult.innerText = rtcScore;
            // downloadResult.innerText = Math.round(downloadInBits / 1048576) + " Mbps";
            // uploadResult.innerText = Math.round(uploadInBits / 1048576) + " Mbps";
            // speedTestResults.style.display = "flex";
            await setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
              testStarted: new Date(),
              speedtestResults: {
                download: Math.round(downloadInBits / 1048576),
                upload: Math.round(uploadInBits / 1048576),
                pingResult: Math.round(latency),
                ratingResult: rtcScore,
              }
            }, {merge: true})
            // if (latency < 20) {
            //   pingResult.style.color = "green";
            // } else if (latency < 50 && latency >= 20) {
            //   pingResult.style.color = "orange";
            // } else if (latency >= 50) {
            //   pingResult.style.color = "red";
            // }
            // if (Math.round(downloadInBits / 1048576) < 10) {
            //   downloadResult.style.color = "red";
            // } else if (Math.round(downloadInBits / 1048576) < 50 && Math.round(downloadInBits / 1048576) >= 10) {
            //   downloadResult.style.color = "orange";
            // } else if (Math.round(downloadInBits / 1048576) >= 50) {
            //   downloadResult.style.color = "green";
            // }
            // if (Math.round(uploadInBits / 1048576) < 10) {
            //   uploadResult.style.color = "red";
            // } else if (Math.round(uploadInBits / 1048576) < 30 && Math.round(uploadInBits / 1048576) >= 10) {
            //   uploadResult.style.color = "orange";
            // } else if (Math.round(uploadInBits / 1048576) >= 30) {
            //   uploadResult.style.color = "green";
            // }
            // if (rtcScore === "poor") {
            //   ratingResult.style.color = "red";
            // } else if (rtcScore === "average") {
            //   ratingResult.style.color = "orange";
            // } else if (rtcScore === "good") {
            //   ratingResult.style.color = "green";
            // }
          }
          let messagesArray = testDoc.data()?.messagesArray ?? [];
          let wpmArrayDb = testDoc.data()?.wpmArray ?? [];
          wpmArray = wpmArrayDb;
          // document.getElementById("liveWPM").innerText = wpmArray.length > 0 ? Math.round(average(wpmArray)) + " WPM" : "0 WPM";
          const chatHistoryDiv = $(`[data-id='messageList']`);
          chatHistoryDiv.animate({
              scrollTop: chatHistoryDiv.prop("scrollHeight")
          }, 500);
          if (wpmArray.length > 0) {
            if (Math.round(average(wpmArray)) > 100) {
              document.querySelector(".copyPasteDetected").style.display = "flex";
              messageText.textContent = "";
              messageText.setAttribute("contenteditable", "false");
              messageText.pointerEvents = "none";
              setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
                metadata: {
                  status: "failed",
                  copyPasteDetected: true,
                }
              }, { merge: true })
              return;
            }
          } else if (testDoc.data().metadata.status === "failed") {
            document.querySelector(".copyPasteDetected").style.display = "flex";
            messageText.textContent = "";
            messageText.setAttribute("contenteditable", "false");
            messageText.pointerEvents = "none";
            setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
              metadata: {
                status: "failed",
                copyPasteDetected: true,
              }
            }, { merge: true })
            return;
          }
        } else {
          localStorage.removeItem("testID");
          await auth.currentUser.getIdToken(true).then(async (idToken) => {
            await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/quizzes/createQuiz`, {
              method: "POST",
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ idToken,
              },
              body: JSON.stringify({
                firebaseUID: auth.currentUser.uid
              })
            }).then((res) => res.json()).then(async (data) => {
              console.log(data);
              localStorage.setItem("testID", data.testId);
              logEvent(analytics, "quiz_started");
              await getDoc(doc(db, "users", auth.currentUser.uid, "tests", data.testId.toString())).then((testDoc) => {
                if (testDoc.exists()) {
                  let questionNumber = testDoc.data().metadata.questionNumber;
                  currentQuestionNumber = questionNumber;
                  let questionResponses = testDoc?.data()?.questionResponses ?? [];
                  currentQuestionNumber = questionNumber;
                  if (questionResponses.length > 0) {
                    document.getElementById("scenarioProgress").innerText = questionResponses.length >= 4 ? "100%" : Math.round((questionResponses.length / 4) * 100) + "%";
                    document.getElementById("basicProgress").innerText = questionResponses.length >= 4 && questionResponses.length >= 9 ? "100%" : (questionResponses.length > 4 ? Math.round(((questionResponses.length - 5)/ 5) * 100) + "%" : "0%");
                    document.getElementById("advancedProgress").innerText = questionResponses.length >= 9 && questionResponses.length >= 14 ? "100%" : (questionResponses.length > 9 ? Math.round(((questionResponses.length - 10) / 5) * 100) + "%" : "0%");
                    document.querySelector(`#progressPercentage`).innerText = Math.round((questionResponses.length / quizQuestions.length) * 100) + "%";
                  }
                  for (var i = 0; i < questionResponses.length; i++) {
                    console.log(i);
                    document.querySelector(`[data-questionNumber="${i}"]`).classList.add("completed");
                    if (i >= 0 && i < 3) {
                      chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                        <div class="messageBox ai">
                          <div class="messageBoxRow">
                            <div class="monogram john">
                            </div>
                            <p class="messageText" data-id="quiz-question${i}">${quizQuestions[i]}</p>
                          </div>
                        </div>`                   
                    } else {
                      chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                        <div class="messageBox ai">
                          <div class="messageBoxRow">
                            <div class="monogram botly">
                            </div>
                            <p class="messageText" data-id="quiz-question${i}">${quizQuestions[i]}</p>
                          </div>
                        </div>`                  
                    }
                    chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                    <div class="messageBox ai">
                      <div class="messageBoxRow">
                        <div class="monogram">
                          <h2 class="messageMonogram">${monogram}</h2>
                        </div>
                        <p class="messageText" data-id="questionResponse-${i}">${questionResponses[i]}</p>
                      </div>
                    </div>`;
                  }
                  wpmArray = testDoc.data()?.wpmArray ?? [];
                  if (questionResponses.length === quizQuestions.length) {
                    const resultsModalTemplate = document.getElementById("resultsModal");
                    const resultsModal = resultsModalTemplate.cloneNode(true);
                    const mainDiv = document.querySelector(".mainDiv");
                    mainDiv.appendChild(resultsModal);
                    resultsModal.id = "resultsModal_testID-" + localStorage.getItem("testID");
                    resultsModal.style.display = "flex";
                    let speedtestResults = testDoc.data().speedtestResults;
                    const apiBody = JSON.stringify({
                      history: messagesArray,
                      wpmAverage: Math.round(average(wpmArray)),
                      quizQuestions: quizQuestions,
                      speedtestResults: speedtestResults
                    });
                    messageText.textContent = "";
                    newConversationDisplay.style.display = "none";
                    // suggestionsDiv.style.display = "none";
                    chatterDiv.querySelector("[data-id='messageList']").style.display = "flex";
                    const string = apiBody + "33746c42427246695962593976455255717a656777766f395152696857676e69";
                    const bodyEncrypted = crypto.createHash("md5").update(string).digest("hex");
                    console.log(string)
                    console.log(bodyEncrypted);
                    resultsModal.querySelector(".averageScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector(".grammarScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector(".fluencyScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector(".creativityScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector(".eiScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector(".wpmAverage").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector(".wpmScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector(".internetScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector(".recommendationText").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector(".examples").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
                    resultsModal.querySelector("#wpmTooltip").addEventListener("mouseover", async (e) => {
                      resultsModal.querySelector(".tooltip").style.display = "flex";
                    });
                    resultsModal.querySelector("#wpmTooltip").addEventListener("mouseleave", async (e) => {
                      resultsModal.querySelector(".tooltip").style.display = "none";
                    });
                    if (testDoc.data()?.scoreRecorded !== undefined) {
                      const responseJSON = testDoc.data().scoreRecorded; 
                      resultsModal.querySelector(".averageScore").innerText = responseJSON.average_score + "/100";
                      resultsModal.querySelector(".grammarScore").innerText = responseJSON.grammar_punctuation + "/100";
                      resultsModal.querySelector(".fluencyScore").innerText = responseJSON.fluency_vocabulary + "/100";
                      resultsModal.querySelector(".creativityScore").innerText = responseJSON.creativity + "/100";
                      resultsModal.querySelector(".eiScore").innerText = responseJSON.emotional_intelligence + "/100";
                      resultsModal.querySelector(".wpmAverage").innerText = Math.round(average(wpmArray));
                      resultsModal.querySelector(".wpmScore").innerText = responseJSON.wpm_score + "/100";
                      resultsModal.querySelector(".internetScore").innerText = responseJSON.internet_score + "/100";
                      resultsModal.querySelector(".recommendationText").innerText = responseJSON.recommendation;
                      resultsModal.querySelector(".examples").innerHTML = responseJSON.examples.map((example) => { return "<div><p>" + example + "</p></div>"}).join("");
                    } else {
                      auth.currentUser.getIdToken(true).then(async (idToken) => {
                        const apiHeaders = {
                          headers: {
                              "Authorization": `Bearer ${idToken}`,
                              "X-Pingback": bodyEncrypted,
                          }
                        }
                        let url = `${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/messages/checkProficiency/`;
                        await axios.post(url, apiBody, apiHeaders).then(async (response) => {
                          console.log(response.data)
                          const responseJSON = JSON.parse(response.data.response);
                          console.log(responseJSON)
                          // resultsModal.querySelector(".resultsModalCloseBtn").style.display = "none";
                          resultsModal.querySelector(".averageScore").innerText = responseJSON.average_score + "/100";
                          resultsModal.querySelector(".grammarScore").innerText = responseJSON.grammar_punctuation + "/100";
                          resultsModal.querySelector(".fluencyScore").innerText = responseJSON.fluency_vocabulary + "/100";
                          resultsModal.querySelector(".creativityScore").innerText = responseJSON.creativity + "/100";
                          resultsModal.querySelector(".eiScore").innerText = responseJSON.emotional_intelligence + "/100";
                          resultsModal.querySelector(".wpmAverage").innerText = Math.round(average(wpmArray));
                          resultsModal.querySelector(".wpmScore").innerText = responseJSON.wpm_score + "/100";
                          resultsModal.querySelector(".internetScore").innerText = responseJSON.internet_score + "/100";
                          resultsModal.querySelector(".recommendationText").innerText = responseJSON.recommendation;
                          resultsModal.querySelector(".examples").innerHTML = responseJSON.examples.map((example) => { return "<div><p>" + example + "</p></div>"}).join("");
                          await setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
                            dateCompleted: new Date(),
                            scoreRecorded: responseJSON
                          }, { merge: true })
                        })
                      })
                    }
                  } else {
                    document.querySelector(`[data-questionNumber="${questionNumber}"]`).classList.add("selected");
                    if (currentQuestionNumber >= 0 && currentQuestionNumber < 2) {
                      chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                        <div class="messageBox ai">
                          <div class="messageBoxRow">
                            <div class="monogram john">
                            </div>
                            <p class="messageText" data-id="quiz-question${currentQuestionNumber}">${quizQuestions[currentQuestionNumber]}</p>
                          </div>
                        </div>`                   
                    } else {
                      chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                        <div class="messageBox ai">
                          <div class="messageBoxRow">
                            <div class="monogram botly">
                            </div>
                            <p class="messageText" data-id="quiz-question${currentQuestionNumber}">${quizQuestions[currentQuestionNumber]}</p>
                          </div>
                        </div>`                  
                    }
                  }
                  const sidebarDiv = $(`.scrollableInnerDiv`);
                  sidebarDiv.animate({
                      scrollTop: sidebarDiv.scrollTop() - sidebarDiv.offset().top + $(`[data-questionNumber="${currentQuestionNumber}"]`).offset().top - 32
                  }, 500);
                  const speedTest = new SpeedTest({
                    turnServerUser: "10d4751126891cd6c6afe03c7b0588c017932abae7cd2f1955db057671c0a1033809f034c1aca94adc213009cd265a0f9110336f3e1d405c7f48ea86469fb8c3",
                    turnServerPass: "aba9b169546eb6dcc7bfb1cdf34544cf95b5161d602e3b5fa7c8342b2e9802fb"
                  })
                  speedTest.onFinish = async (result) => {
                    let summary = result.getSummary();
                    let scores = result.getScores();
                    let latency = summary.latency;
                    let downloadInBits = summary.download;
                    let uploadInBits = summary.upload;
                    let rtcScore = scores["rtc"].classificationName;
                    // const speedTestResults = document.getElementById("speedTestResults");
                    // const downloadResult = document.getElementById("downloadResult");
                    // const uploadResult = document.getElementById("uploadResult");
                    // const pingResult = document.getElementById("pingResult");
                    // const ratingResult = document.getElementById("ratingResult");
                    // console.log(summary);
                    // pingResult.innerText = Math.round(latency) + " ms";
                    // ratingResult.innerText = rtcScore;
                    // downloadResult.innerText = Math.round(downloadInBits / 1048576) + " Mbps";
                    // uploadResult.innerText = Math.round(uploadInBits / 1048576) + " Mbps";
                    // speedTestResults.style.display = "flex";
                    await setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
                      testStarted: new Date(),
                      speedtestResults: {
                        download: Math.round(downloadInBits / 1048576),
                        upload: Math.round(uploadInBits / 1048576),
                        pingResult: Math.round(latency),
                        ratingResult: rtcScore,
                      }
                    }, {merge: true})
                    // if (latency < 20) {
                    //   pingResult.style.color = "green";
                    // } else if (latency < 50 && latency >= 20) {
                    //   pingResult.style.color = "orange";
                    // } else if (latency >= 50) {
                    //   pingResult.style.color = "red";
                    // }
                    // if (Math.round(downloadInBits / 1048576) < 10) {
                    //   downloadResult.style.color = "red";
                    // } else if (Math.round(downloadInBits / 1048576) < 50 && Math.round(downloadInBits / 1048576) >= 10) {
                    //   downloadResult.style.color = "orange";
                    // } else if (Math.round(downloadInBits / 1048576) >= 50) {
                    //   downloadResult.style.color = "green";
                    // }
                    // if (Math.round(uploadInBits / 1048576) < 10) {
                    //   uploadResult.style.color = "red";
                    // } else if (Math.round(uploadInBits / 1048576) < 30 && Math.round(uploadInBits / 1048576) >= 10) {
                    //   uploadResult.style.color = "orange";
                    // } else if (Math.round(uploadInBits / 1048576) >= 30) {
                    //   uploadResult.style.color = "green";
                    // }
                    // if (rtcScore === "poor") {
                    //   ratingResult.style.color = "red";
                    // } else if (rtcScore === "average") {
                    //   ratingResult.style.color = "orange";
                    // } else if (rtcScore === "good") {
                    //   ratingResult.style.color = "green";
                    // }
                  }
                  let messagesArray = testDoc.data()?.messagesArray ?? [];
                  let wpmArrayDb = testDoc.data()?.wpmArray ?? [];
                  wpmArray = wpmArrayDb;
                  // document.getElementById("liveWPM").innerText = wpmArray.length > 0 ? Math.round(average(wpmArray)) + " WPM" : "0 WPM";
                  const chatHistoryDiv = $(`[data-id='messageList']`);
                  chatHistoryDiv.animate({
                      scrollTop: chatHistoryDiv.prop("scrollHeight")
                  }, 500);
                  if (wpmArray.length > 0) {
                    if (Math.round(average(wpmArray)) > 100) {
                      document.querySelector(".copyPasteDetected").style.display = "flex";
                      messageText.textContent = "";
                      messageText.setAttribute("contenteditable", "false");
                      messageText.pointerEvents = "none";
                      setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
                        metadata: {
                          status: "failed",
                          copyPasteDetected: true,
                        }
                      }, { merge: true })
                      return;
                    }
                  } else if (testDoc.data().metadata.status === "failed") {
                    document.querySelector(".copyPasteDetected").style.display = "flex";
                    messageText.textContent = "";
                    messageText.setAttribute("contenteditable", "false");
                    messageText.pointerEvents = "none";
                    setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
                      metadata: {
                        status: "failed",
                        copyPasteDetected: true,
                      }
                    }, { merge: true })
                    return;
                  }
                }
              }).catch((error) => {
                console.log(error)
              })
            })
          })
        }
      })
    }    
  }
}

createAccountBtn.addEventListener("click", function () {
  loginDiv.style.display = "none";
  createAccountDiv.style.display = "flex";
})

createAccountStartBtn.addEventListener("click", async function () {
  const email = createAccountEmail.value;
  const password = createAccountPassword.value;
  if (email !== "" && password !== "") {
    createAccountStartBtn.innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`;
    await createAccount(email, password).then(async (res) => {
      console.log(res)
      if (res.status === "success") {
        createAccountDiv.style.display = "none";
        accountInfoScreen.style.display = "flex";
        createAccountStartBtn.innerText = `Sign up`;
      } else {
        createAccountScreenErrorLbl.style.display = "flex";
      }
    }).catch((e) => {
      console.error(e)
      createAccountDiv.querySelector(".errorLbl").style.display = "flex";
      createAccountStartBtn.innerText = `Continue`;
    })
  } else {
    createAccountDiv.querySelector(".errorLbl").style.display = "flex";
  }
})

document.querySelector(".sidebar").addEventListener("click", function () {
  if (window.screen.width <= 550) {
    document.querySelector(".sidebar").classList.toggle("display-flex")
  }
})


bottomMenuButton.addEventListener("click", function (e) {
  e.preventDefault()
  e.stopImmediatePropagation()
  bottomMenuList.classList.add("active")
})

bottomMenuButton.addEventListener("mouseover", function () {
  bottomMenuList.classList.add("active")
})

document.querySelector(".bottomMenuList").addEventListener("mouseleave", (e) => {
  bottomMenuList.classList.remove("active")
})

messageSendBtn.addEventListener("click", async () => {
  if (messageText.textContent.length > 0) {
    const average = array => array.reduce((a, b) => a + b) / array.length;
    logEvent(analytics, "quiz_question_answered", {
      questionNumber: currentQuestionNumber,
      response: messageText.textContent
    })
    const setNumWordsInText = b => {
      let s = b
      s = s.replace(/(^\s*)|(\s*$)/gi, "")
      s = s.replace(/[ ]{2,}/gi, " ")
      s = s.replace(/\n /, "\n")
      return s.split(" ").length
    }
    const wordsInText = setNumWordsInText(messageText.textContent);
    console.log(startDate);
    const seconds = (new Date().getTime() - new Date(startDate).getTime()) / 1000;
    console.log(seconds)
    const calculateWPM = (t, w) => (w / t) * 60;
    const currentWPM = calculateWPM(seconds, wordsInText);
    wpmArray.push(currentWPM);
    console.log(wpmArray);
    console.log(currentWPM);
    startDate = null;
    // document.getElementById("liveWPM").innerText = Math.round(average(wpmArray)) + " WPM";
    if (Math.round(average(wpmArray)) > 100) {
      document.querySelector(".copyPasteDetected").style.display = "flex";
      messageText.textContent = "";
      messageText.setAttribute("contenteditable", "false");
      messageText.pointerEvents = "none";
      await setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
        metadata: {
          status: "failed",
          copyPasteDetected: true,
        }
      }, { merge: true })
      return;
    }
      await getDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID"))).then(async (testDoc) => {
        if (testDoc.exists()) {
          let questionResponses = testDoc.data()?.questionResponses ?? [];
          let latestMessage = messageText.textContent;
          questionResponses.push(latestMessage);
          const chatHistoryDiv = $(`[data-id='messageList']`);
          const sidebarDiv = $(`.scrollableInnerDiv`);
          chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
          <div class="messageBox ai">
            <div class="messageBoxRow">
              <div class="monogram">
                  <h2 class="messageMonogram">${monogram}</h2>
              </div>
              <p class="messageText">${latestMessage}</p>
            </div>
          </div>`;
          chatHistoryDiv.animate({
              scrollTop: chatHistoryDiv.prop("scrollHeight")
          }, 500);
          let currentDate = new Date();
          document.querySelector(`[data-questionNumber="${currentQuestionNumber}"]`).classList.add("completed");
          let status;
          if (questionResponses.length < quizQuestions.length) {
            currentQuestionNumber += 1;
            status = "incomplete";
            if (currentQuestionNumber >= 0 && currentQuestionNumber < 2) {
              chatterDiv.querySelector(".modelInfo").style.display = "flex";
              chatterDiv.querySelector(".fanInfo").style.display = "flex";
            } else {
              chatterDiv.querySelector(".modelInfo").style.display = "none";
              chatterDiv.querySelector(".fanInfo").style.display = "none";
            }
          } else {
            status = "complete";
          }
          await setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
            questionResponses: questionResponses,
            wpmArray: wpmArray,
            metadata: {
              status,
              questionNumber: currentQuestionNumber,
              lastUpdated: currentDate.getTime(),
            }
          }, {merge: true}).then(() => {
            if (questionResponses.length > 0) {
              document.getElementById("scenarioProgress").innerText = questionResponses.length >= 4 ? "100%" : Math.round((questionResponses.length / 4) * 100) + "%";
              document.getElementById("basicProgress").innerText = questionResponses.length >= 4 && questionResponses.length >= 9 ? "100%" : (questionResponses.length > 4 ? Math.round(((questionResponses.length - 5)/ 5) * 100) + "%" : "0%");
              document.getElementById("advancedProgress").innerText = questionResponses.length >= 9 && questionResponses.length >= 14 ? "100%" : (questionResponses.length > 9 ? Math.round(((questionResponses.length - 10) / 5) * 100) + "%" : "0%");
              document.querySelector(`#progressPercentage`).innerText = Math.round((questionResponses.length / quizQuestions.length) * 100) + "%";
            }
            messageText.textContent = "";
            if (status === "complete") {
              if (currentQuestionNumber >= 0 && currentQuestionNumber < 2) {
                chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                  <div class="messageBox ai">
                    <div class="messageBoxRow">
                      <div class="monogram john">
                      </div>
                      <p class="messageText" data-id="quiz-question${currentQuestionNumber}">${quizQuestions[currentQuestionNumber]}</p>
                    </div>
                  </div>`                   
              } else {
                chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                  <div class="messageBox ai">
                    <div class="messageBoxRow">
                      <div class="monogram botly">
                      </div>
                      <p class="messageText" data-id="quiz-question${currentQuestionNumber}">${quizQuestions[currentQuestionNumber]}</p>
                    </div>
                  </div>`                  
              }
              chatHistoryDiv.animate({
                  scrollTop: chatHistoryDiv.prop("scrollHeight")
              }, 500);
              sidebarDiv.animate({
                  scrollTop: sidebarDiv.scrollTop() - sidebarDiv.offset().top + $(`[data-questionNumber="${currentQuestionNumber}"]`).offset().top - 32
              }, 500);
              const resultsModalTemplate = document.getElementById("resultsModal");
              const resultsModal = resultsModalTemplate.cloneNode(true);
              const mainDiv = document.querySelector(".mainDiv");
              mainDiv.appendChild(resultsModal);
              resultsModal.id = "resultsModal_testID-" + localStorage.getItem("testID");
              resultsModal.style.display = "flex";
              let speedtestResults = testDoc.data().speedtestResults;
              const apiBody = JSON.stringify({
                history: questionResponses,
                wpmAverage: Math.round(average(wpmArray)),
                quizQuestions: quizQuestions,
                speedtestResults: speedtestResults
              });
              messageText.textContent = "";
              newConversationDisplay.style.display = "none";
              // suggestionsDiv.style.display = "none";
              chatterDiv.querySelector("[data-id='messageList']").style.display = "flex";
              const string = apiBody + "33746c42427246695962593976455255717a656777766f395152696857676e69";
              const bodyEncrypted = crypto.createHash("md5").update(string).digest("hex");
              console.log(string)
              console.log(bodyEncrypted);
              resultsModal.querySelector(".averageScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
              resultsModal.querySelector(".grammarScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
              resultsModal.querySelector(".fluencyScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
              resultsModal.querySelector(".creativityScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
              resultsModal.querySelector(".eiScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
              resultsModal.querySelector(".wpmAverage").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
              resultsModal.querySelector(".wpmScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
              resultsModal.querySelector(".internetScore").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
              resultsModal.querySelector(".recommendationText").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
              resultsModal.querySelector(".examples").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`
              resultsModal.querySelector("#wpmTooltip").addEventListener("mouseover", async (e) => {
                resultsModal.querySelector(".tooltip").style.display = "flex";
              });
              resultsModal.querySelector("#wpmTooltip").addEventListener("mouseleave", async (e) => {
                resultsModal.querySelector(".tooltip").style.display = "none";
              });
              if (testDoc.data()?.scoreRecorded !== undefined) {
                const responseJSON = testDoc.data().scoreRecorded;
                const average = array => array.reduce((a, b) => a + b) / array.length;  
                resultsModal.querySelector(".averageScore").innerText = responseJSON.average_score + "/100";
                resultsModal.querySelector(".grammarScore").innerText = responseJSON.grammar_punctuation + "/100";
                resultsModal.querySelector(".fluencyScore").innerText = responseJSON.fluency_vocabulary + "/100";
                resultsModal.querySelector(".creativityScore").innerText = responseJSON.creativity + "/100";
                resultsModal.querySelector(".eiScore").innerText = responseJSON.emotional_intelligence + "/100";
                resultsModal.querySelector(".wpmAverage").innerText = Math.round(average(wpmArray));
                resultsModal.querySelector(".wpmScore").innerText = responseJSON.wpm_score + "/100";
                resultsModal.querySelector(".internetScore").innerText = responseJSON.internet_score + "/100";
                resultsModal.querySelector(".recommendationText").innerText = responseJSON.recommendation;
                resultsModal.querySelector(".examples").innerHTML = responseJSON.examples.map((example) => { return "<div><p>" + example + "</p></div>"}).join("");
              } else {
                auth.currentUser.getIdToken(true).then(async (idToken) => {
                  const apiHeaders = {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                        "X-Pingback": bodyEncrypted,
                    }
                  }
                  let url = `${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/messages/checkProficiency/`;
                  await axios.post(url, apiBody, apiHeaders).then(async (response) => {
                    console.log(response.data)
                    const responseJSON = JSON.parse(response.data.response);
                    logEvent(analytics, "results_generated", responseJSON);
                    console.log(responseJSON)
                    const average = array => array.reduce((a, b) => a + b) / array.length;
                    // resultsModal.querySelector(".resultsModalCloseBtn").style.display = "none";
                    resultsModal.querySelector(".averageScore").innerText = responseJSON.average_score + "/100";
                    resultsModal.querySelector(".grammarScore").innerText = responseJSON.grammar_punctuation + "/100";
                    resultsModal.querySelector(".fluencyScore").innerText = responseJSON.fluency_vocabulary + "/100";
                    resultsModal.querySelector(".creativityScore").innerText = responseJSON.creativity + "/100";
                    resultsModal.querySelector(".eiScore").innerText = responseJSON.emotional_intelligence + "/100";
                    resultsModal.querySelector(".wpmAverage").innerText = Math.round(average(wpmArray));
                    resultsModal.querySelector(".wpmScore").innerText = responseJSON.wpm_score + "/100";
                    resultsModal.querySelector(".internetScore").innerText = responseJSON.internet_score + "/100";
                    resultsModal.querySelector(".recommendationText").innerText = responseJSON.recommendation;
                    resultsModal.querySelector(".examples").innerHTML = responseJSON.examples.map((example) => { return "<div><p>" + example + "</p></div>"}).join("");
                    await setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
                      dateCompleted: new Date(),
                      scoreRecorded: responseJSON
                    }, { merge: true })
                  })
                })
              }
            } else {
              if (currentQuestionNumber >= 0 && currentQuestionNumber < 2) {
                chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                  <div class="messageBox ai">
                    <div class="messageBoxRow">
                      <div class="monogram john">
                      </div>
                      <p class="messageText" data-id="quiz-question${currentQuestionNumber}">${quizQuestions[currentQuestionNumber]}</p>
                    </div>
                  </div>`                   
              } else {
                chatterDiv.querySelector("[data-id='messageList']").innerHTML += `
                  <div class="messageBox ai">
                    <div class="messageBoxRow">
                      <div class="monogram botly">
                      </div>
                      <p class="messageText" data-id="quiz-question${currentQuestionNumber}">${quizQuestions[currentQuestionNumber]}</p>
                    </div>
                  </div>`                  
              }
              chatHistoryDiv.animate({
                  scrollTop: chatHistoryDiv.prop("scrollHeight")
              }, 500);
              sidebarDiv.animate({
                  scrollTop: sidebarDiv.scrollTop() - sidebarDiv.offset().top + $(`[data-questionNumber="${currentQuestionNumber}"]`).offset().top - 32
              }, 500);              
            }
          })
        }
      })
  }
})

document.querySelector("[data-id='trainerMessageSendBtn']").addEventListener("click", async () => {
  if (document.querySelector("[data-id='trainerMessageText']").textContent.length > 0) {
    const average = array => array.reduce((a, b) => a + b) / array.length;
    logEvent(analytics, "trainer_response", {
      response: document.querySelector("[data-id='trainerMessageText']").textContent
    })
    const setNumWordsInText = b => {
      let s = b
      s = s.replace(/(^\s*)|(\s*$)/gi, "")
      s = s.replace(/[ ]{2,}/gi, " ")
      s = s.replace(/\n /, "\n")
      return s.split(" ").length
    }
    const wordsInText = setNumWordsInText(document.querySelector("[data-id='trainerMessageText']").textContent);
    console.log(startDate);
    const seconds = (new Date().getTime() - new Date(startDate).getTime()) / 1000;
    console.log(seconds)
    const calculateWPM = (t, w) => (w / t) * 60;
    const currentWPM = calculateWPM(seconds, wordsInText);
    wpmArray.push(currentWPM);
    console.log(wpmArray);
    console.log(currentWPM);
    startDate = null;
    if (Math.round(average(wpmArray)) > 100) {
      await setDoc(doc(db, "users", auth.currentUser.uid, "trainers", localStorage.getItem("trainerID")), {
        metadata: {
          copyPasteDetected: true,
        }
      }, { merge: true })
    }
      await getDoc(doc(db, "users", auth.currentUser.uid, "trainers", localStorage.getItem("trainerID"))).then(async (testDoc) => {
        if (testDoc.exists()) {
          let messages = testDoc.data()?.messages ?? [];
          let latestMessage = document.querySelector("[data-id='trainerMessageText']").textContent;
          messages.push(fanMessages[currentFanMessageNumber]);
          messages.push(latestMessage);
          const chatHistoryDiv = $(`[data-id='trainerMessageList']`);
          const sidebarDiv = $(`.scrollableInnerDiv`);
          document.querySelector("[data-id='trainerDiv']").querySelector("[data-id='trainerMessageList']").innerHTML += `
          <div class="messageBox ai">
            <div class="messageBoxRow">
              <div class="monogram">
                  <h2 class="messageMonogram">${monogram}</h2>
              </div>
              <p class="messageText">${latestMessage}</p>
            </div>
          </div>`;
          chatHistoryDiv.animate({
              scrollTop: chatHistoryDiv.prop("scrollHeight")
          }, 500);
          let currentDate = new Date();
          let status;
          if (messages.length < (fanMessages.length + modelMessages.length)) {
            currentFanMessageNumber += 1;
            currentModelMessageNumber += 1;
            status = "incomplete";
          } else {
            status = "complete";
            messages.push(fanMessages[currentFanMessageNumber + 1]);
          }
          await setDoc(doc(db, "users", auth.currentUser.uid, "trainers", localStorage.getItem("trainerID")), {
            messages: messages,
            wpmArray: wpmArray,
            metadata: {
              status,
              lastUpdated: currentDate.getTime(),
              currentFanMessageNumber: currentFanMessageNumber,
              currentModelMessageNumber: currentModelMessageNumber,
            }
          }, {merge: true}).then(async () => {
            document.querySelector("[data-id='trainerMessageText']").textContent = "";
            if (status === "complete") {
              document.querySelector("[data-id='trainerDiv']").querySelector("[data-id='trainerMessageList']").innerHTML += `
                <div class="messageBox ai">
                  <div class="messageBoxRow">
                    <div class="monogram botly">
                    </div>
                    <p class="messageText">${fanMessages[currentFanMessageNumber]}</p>
                  </div>
                </div>`
              document.querySelector("[data-id='trainerDiv']").querySelector("[data-id='trainerMessageList']").innerHTML += `
                <div class="messageBox ai">
                  <div class="messageBoxRow">
                    <div class="monogram botly">
                    </div>
                    <p class="messageText completed">You have completed this trainer. You can run through it again by clicking the trainer in the sidebar.</p>
                  </div>
                </div>`
              await setDoc(doc(db, "users", auth.currentUser.uid, "trainers", localStorage.getItem("trainerID")), {
                dateCompleted: new Date()
              }, { merge: true })
              document.querySelector("[data-id='trainerMessageText']").textContent = "";
              document.querySelector("[data-id='trainerMessageText']").setAttribute("contenteditable", "false");
              document.querySelector("[data-id='trainerMessageText']").pointerEvents = "none";
              document.querySelector(".nextResponse").style.display = "none";
            } else {
              document.querySelector("[data-id='trainerDiv']").querySelector("[data-id='trainerMessageList']").innerHTML += `
                <div class="messageBox ai">
                  <div class="messageBoxRow">
                    <div class="monogram botly">
                    </div>
                    <p class="messageText">${fanMessages[currentFanMessageNumber]}</p>
                  </div>
                </div>`
              document.querySelector(".fansUpcomingMessage").innerText = fanMessages[currentFanMessageNumber + 1]
              document.querySelector(".nextResponse").style.display = "flex";
              
              chatHistoryDiv.animate({
                  scrollTop: chatHistoryDiv.prop("scrollHeight")
              }, 500);
              sidebarDiv.animate({
                  scrollTop: sidebarDiv.scrollTop() - sidebarDiv.offset().top + $(`[data-questionNumber="${currentQuestionNumber}"]`).offset().top - 32
              }, 500);              
            }
          })
        }
      })
  }
})

window.copyText = async (i) => {
  let messageText = document.querySelector(`[data-id="message-text-${i}"]`);
  let copiedBanner = document.querySelector(`[data-id="message-copy-banner${i}"]`);
  navigator.clipboard.writeText(messageText.innerText);
  copiedBanner.style.display = "flex";
  setTimeout(() => {
    copiedBanner.style.display = "none";
  }, 2500)
}

$("[data-id='messageText']").on("input", () => {
  if (messageText.textContent.length > 0) {
    messageSendBtn.disabled = false;
    messageSendBtn.classList.add("active");
  } else {
    messageSendBtn.disabled = true;
    messageSendBtn.classList.remove("active");
  }
})

$("[data-id='messageText']").on("keydown", (e) => {
  e.stopImmediatePropagation();
  if (startDate === null) {
    startDate = new Date();
  }
  if (e.key === "Enter") {
    e.preventDefault();
    messageSendBtn.click();
  }
})

$("[data-id='trainerMessageText']").on("input", () => {
  if (document.querySelector("[data-id='trainerMessageText']").textContent.length > 0) {
    document.querySelector("[data-id='trainerMessageSendBtn']").disabled = false;
    document.querySelector("[data-id='trainerMessageSendBtn']").classList.add("active");
  } else {
    document.querySelector("[data-id='trainerMessageSendBtn']").disabled = true;
    document.querySelector("[data-id='trainerMessageSendBtn']").classList.remove("active");
  }
})

$("[data-id='trainerMessageText']").on("keydown", (e) => {
  e.stopImmediatePropagation();
  if (startDate === null) {
    startDate = new Date();
  }
  if (e.key === "Enter") {
    e.preventDefault();
    document.querySelector("[data-id='trainerMessageSendBtn']").click();
  }
})

window.signOut = async () => {
  await signOut(auth);
  localStorage.removeItem("testID");
  localStorage.removeItem("trainerID");
  window.localStorage.clear();
  chatterDiv.style.display = "none";
  loginDiv.style.display = "flex";
  document.querySelector("[data-id='agencyDiv']").style.display = "none";
}

document.querySelector("[data-id='signOutBtn']").addEventListener("click", (e) => {
  window.signOut();
})

continueToProficiencyTest.addEventListener("click", async (e) => {
  await setDoc(doc(db, "users", auth.currentUser.uid), {
    stage: "proficiencyTest"
  }, {merge: true}).then(() => {
    introductionArea.style.display = "none";
    proficiencyTestArea.style.display = "flex";
    messageArea.style.display = "none";
    newConversationDisplay.style.display = "none";
    proficiencyTestIntro.style.display = "flex";
  })
})

startProficiencyTest.addEventListener("click", async (e) => {
  await setDoc(doc(db, "users", auth.currentUser.uid), {
    stage: "testing"
  }, {merge: true}).then(async () => {
    const testID = new Date().getTime().toString();
    localStorage.setItem("testID", testID);
    await setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
      testStarted: new Date(),
    }, {merge: true}).then(() => {
      introductionArea.style.display = "none";
      messageArea.style.display = "flex";
      proficiencyTestArea.style.display = "none";
      newConversationDisplay.style.display = "none";
      proficiencyTestIntro.style.display = "none";
      proficiencyTestScreen.style.display = 'flex';
      const speedTest = new SpeedTest({
        turnServerUser: "10d4751126891cd6c6afe03c7b0588c017932abae7cd2f1955db057671c0a1033809f034c1aca94adc213009cd265a0f9110336f3e1d405c7f48ea86469fb8c3",
        turnServerPass: "aba9b169546eb6dcc7bfb1cdf34544cf95b5161d602e3b5fa7c8342b2e9802fb"
      })
      speedTest.onFinish = async (result) => {
        let summary = result.getSummary();
        let scores = result.getScores();
        let latency = summary.latency;
        let downloadInBits = summary.download;
        let uploadInBits = summary.upload;
        let rtcScore = scores["rtc"].classificationName;
        // const speedTestResults = document.getElementById("speedTestResults");
        // const downloadResult = document.getElementById("downloadResult");
        // const uploadResult = document.getElementById("uploadResult");
        // const pingResult = document.getElementById("pingResult");
        // const ratingResult = document.getElementById("ratingResult");
        // console.log(summary);
        // pingResult.innerText = Math.round(latency) + " ms";
        // ratingResult.innerText = rtcScore;
        // downloadResult.innerText = Math.round(downloadInBits / 1048576) + " Mbps";
        // uploadResult.innerText = Math.round(uploadInBits / 1048576) + " Mbps";
        // speedTestResults.style.display = "flex";
        await setDoc(doc(db, "users", auth.currentUser.uid, "tests", localStorage.getItem("testID")), {
          testStarted: new Date(),
          speedtestResults: {
            download: Math.round(downloadInBits / 1048576),
            upload: Math.round(uploadInBits / 1048576),
            pingResult: Math.round(latency),
            ratingResult: rtcScore,
          }
        }, {merge: true})
        // if (latency < 20) {
        //   pingResult.style.color = "green";
        // } else if (latency < 50 && latency >= 20) {
        //   pingResult.style.color = "orange";
        // } else if (latency >= 50) {
        //   pingResult.style.color = "red";
        // }
        // if (Math.round(downloadInBits / 1048576) < 10) {
        //   downloadResult.style.color = "red";
        // } else if (Math.round(downloadInBits / 1048576) < 50 && Math.round(downloadInBits / 1048576) >= 10) {
        //   downloadResult.style.color = "orange";
        // } else if (Math.round(downloadInBits / 1048576) >= 50) {
        //   downloadResult.style.color = "green";
        // }
        // if (Math.round(uploadInBits / 1048576) < 10) {
        //   uploadResult.style.color = "red";
        // } else if (Math.round(uploadInBits / 1048576) < 30 && Math.round(uploadInBits / 1048576) >= 10) {
        //   uploadResult.style.color = "orange";
        // } else if (Math.round(uploadInBits / 1048576) >= 30) {
        //   uploadResult.style.color = "green";
        // }
        // if (rtcScore === "poor") {
        //   ratingResult.style.color = "red";
        // } else if (rtcScore === "average") {
        //   ratingResult.style.color = "orange";
        // } else if (rtcScore === "good") {
        //   ratingResult.style.color = "green";
        // }
      }      
    })
  })
})

document.getElementById("addedChatter").addEventListener("click", async (e) => {
  window.location.reload()
})
document.querySelector("[data-id='chatterModalCloseBtn']").addEventListener("click", async (e) => {
  document.getElementById("chatterModal").style.display = "none";
})

agencyRadioDiv.addEventListener("click", async (e) => {
  e.stopImmediatePropagation();
  if (agencyRadio.checked === true) {
    agencyRadio.checked = false;
    agencyRadioDiv.classList.remove("active");
  } else {
    if (chatterRadio.checked === true) {
      chatterRadio.checked = false;
      chatterRadioDiv.classList.remove("active");
      document.querySelector("#createAccountAgencyCodeDiv").style.display = "none";
    }
    agencyRadio.checked = true;
    agencyRadioDiv.classList.add("active");
  }
})

chatterRadioDiv.addEventListener("click", async (e) => {
  e.stopImmediatePropagation();
  if (chatterRadio.checked === true) {
    chatterRadio.checked = false;
    chatterRadioDiv.classList.remove("active");
    document.querySelector("#createAccountAgencyCodeDiv").style.display = "none";
  } else {
    if (agencyRadio.checked === true) {
      agencyRadio.checked = false;
      agencyRadioDiv.classList.remove("active");
    }
    chatterRadio.checked = true;
    chatterRadioDiv.classList.add("active");
    document.querySelector("#createAccountAgencyCodeDiv").style.display = "flex";
  }
})

saveAccountInfoBtn.addEventListener("click", async (e) => {
  if ((agencyRadio.checked !== false || chatterRadio.checked !== false) && createAccountFullName.value !== "") {
    saveAccountInfoBtn.innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`;
    if (createAccountFullName.value !== '') {
      const isAgency = agencyRadio.checked;
      await saveAccountInfo(createAccountFullName.value, isAgency, document.querySelector("[data-id='createAccountAgencyCode']").value).then((res) => {
        console.log(res)
        if (res.status === "success") {
          saveAccountInfoBtn.innerText = `Start test`;
          accountInfoScreen.style.display = "none";
          if (isAgency) {
            loadAgencyScreen();
          } else {
            loadChat();
          }
        } else {
          accountInfoScreenErrorLbl.style.display = "flex";
        }
      }).catch((err) => {
        console.error(err)
        accountInfoScreenErrorLbl.style.display = "flex";
      })
    } else {
      accountInfoScreenErrorLbl.style.display = "flex";
    }
  } else {
    accountInfoScreenErrorLbl.style.display = "flex";
  }
})

window.loadTestHistory = async () => {
  const testHistoryModalTemplate = document.querySelector("#testHistoryModal");
  const testHistoryModal = testHistoryModalTemplate.cloneNode(true);
  const mainDiv = document.querySelector(".mainDiv");
  mainDiv.appendChild(testHistoryModal);
  testHistoryModal.style.display = "flex";
  await getDocs(collection(db, "users", auth.currentUser.uid, "tests")).then(async (testsSnapshot) => {
    testHistoryModal.querySelector("[data-id='testHistoryModalCloseBtn']").addEventListener("click", async (e) => {
      testHistoryModal.style.display = "none";
      testHistoryModal.remove();
    })
    for (const testDoc of testsSnapshot.docs) {
      if (testDoc.data().metadata.status === "complete" || testDoc.data().metadata.status === "failed") {
        const testDate = new Date(1970, 0, 1);
        testDate.setSeconds(parseInt(testDoc.id) / 1000)
        const score = testDoc.data().metadata.status === 'complete' ? testDoc.data().scoreRecorded.average_score + "/100" : "Failed - Copy & Paste Detected";
        const row = document.createElement("tr");
        const cell1 = document.createElement("td");
        const cell2 = document.createElement("td");
        const cell3 = document.createElement("td");
        cell1.innerText = testDate.toLocaleDateString();
        cell2.innerText = score;
        cell3.innerHTML = testDoc.data().metadata.status === 'complete' ? `<button class="viewBtn" data-id="${testDoc.id}">View Results</button>` : 'No results available';
        row.appendChild(cell1);
        row.appendChild(cell2);
        row.appendChild(cell3);
        testHistoryModal.querySelector(".resultsTable").querySelector("tbody").appendChild(row);
        testHistoryModal.querySelector(`[data-id='${testDoc.id}']`).addEventListener("click", async (e) => {
          testHistoryModal.style.display = "none";
          const resultsModalTemplate = document.getElementById("resultsModal");
          const resultsModal = resultsModalTemplate.cloneNode(true);
          const mainDiv = document.querySelector(".mainDiv");
          mainDiv.appendChild(resultsModal);
          resultsModal.id = "resultsModal_testID-" + testDoc.id;
          resultsModal.style.display = "flex";
          const responseJSON = testDoc.data().scoreRecorded;
          const wpmArray = testDoc.data()?.wpmArray ?? [];
          const average = array => array.reduce((a, b) => a + b) / array.length;  
          resultsModal.querySelector(".averageScore").innerText = responseJSON.average_score + "/100";
          resultsModal.querySelector(".grammarScore").innerText = responseJSON.grammar_punctuation + "/100";
          resultsModal.querySelector(".fluencyScore").innerText = responseJSON.fluency_vocabulary + "/100";
          resultsModal.querySelector(".creativityScore").innerText = responseJSON.creativity + "/100";
          resultsModal.querySelector(".eiScore").innerText = responseJSON.emotional_intelligence + "/100";
          resultsModal.querySelector(".wpmAverage").innerText = Math.round(average(wpmArray));
          resultsModal.querySelector(".wpmScore").innerText = responseJSON.wpm_score + "/100";
          resultsModal.querySelector(".internetScore").innerText = responseJSON.internet_score + "/100";
          resultsModal.querySelector(".recommendationText").innerText = responseJSON.recommendation;
          resultsModal.querySelector(".examples").innerHTML = responseJSON.examples.map((example) => { return "<div><p>" + example + "</p></div>"}).join("");
          resultsModal.querySelector("[data-id='resultsModalCloseBtn']").style.display = "flex";
          resultsModal.querySelector("#resultsStartNewTest").style.display = "none";
          resultsModal.querySelector("[data-id='resultsModalCloseBtn']").addEventListener("click", async (e) => {
            resultsModal.style.display = "none";
            resultsModal.remove();
            testHistoryModal.style.display = "flex";
          })
          resultsModal.querySelector("#wpmTooltip").addEventListener("mouseover", async (e) => {
            resultsModal.querySelector(".tooltip").style.display = "flex";
          });
          resultsModal.querySelector("#wpmTooltip").addEventListener("mouseleave", async (e) => {
            resultsModal.querySelector(".tooltip").style.display = "none";
          });
          // resultsModal.querySelector("#printResults").addEventListener("click", async (e) => {
          //   const pdf = new jsPDF();
          //   const element = resultsModal.querySelector(".resultsModalContents");
           
          //   pdf.html(element, {
          //    callback: function (pdf) {
           
          //  // Save the PDF to a file or display it
          //     pdf.save(`Botly Dojo Results - ${testDate.toLocaleDateString()}.pdf`);
          //    },
          //   });
          // })
        })
      }
    }
  })
}

document.querySelector("[data-id='betaSurveyModalCloseBtn']").addEventListener("click", async (e) => {
  document.querySelector("#betaSurveyModal").style.display = "none";
  await setDoc(doc(db, "users", auth.currentUser.uid), {
    completedSurvey: true
  }, { merge: true });
})

document.querySelector("#addTrainer").addEventListener("click", async (e) => {
  document.querySelector("#addTrainerModal").style.display = "flex";
})

document.querySelector("[data-id='addTrainerModalCloseBtn']").addEventListener("click", async (e) => {
  document.querySelector("#addTrainerModal").style.display = "none";
})

document.querySelector("#uploadTrainer").addEventListener("click", async (e) => {
  document.querySelector("#uploadTrainer").innerHTML = `<i class="fa-solid fa-spinner fa-spin"></i>`;  
  auth.currentUser.getIdToken(true).then(async (idToken) => {
    await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/trainers/createTrainer`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ idToken,
      },
      body: JSON.stringify({
        firebaseUID: auth.currentUser.uid,
        trainerName: document.querySelector("[data-id='trainerName']").value,
        trainerText: document.querySelector("#scriptInput").value,
        modelDetails: {
          name: document.querySelector("[data-id='modelName']").value,
          age: document.querySelector("[data-id='modelAge']").value,
          location: document.querySelector("[data-id='modelLocation']").value,
          interests: document.querySelector("[data-id='modelInterests']").value
        }
      })
    }).then((res) => res.json()).then(async (res) => {
      document.querySelector("[data-id='trainerName']").value = '';
      document.querySelector("#scriptInput").value = '';
      document.querySelector("#addTrainerModal").style.display = "none";
      await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/trainers/getTrainers`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ idToken,
        },
        body: JSON.stringify({
          firebaseUID: auth.currentUser.uid,
        })
      }).then((res) => res.json()).then(async (res) => {
        document.querySelector(".trainerCards").innerHTML = '';
        for (const trainer of Object.keys(res?.trainers)) {
            document.querySelector(".trainerCards").innerHTML += `
              <div class='trainerCard'>
                <h1>${res.trainers[trainer].trainerName}</h1>
                <button class="button-primary">
                  View Results
                </button>
                <div>
                  <h4>Model Details:</h4>
                  <p><b>Name:</b> ${res.trainers[trainer].modelDetails.name}</p>
                  <p><b>Age:</b> ${res.trainers[trainer].modelDetails.age}</p>
                  <p><b>Location:</b> ${res.trainers[trainer].modelDetails.location}</p>
                  <p><b>Interests:</b> ${res.trainers[trainer].modelDetails.interests}</p>
                </div>
              </div>
            `
        }
      })
    })
  })
})

async function loadTrainer(trainerName) {
  const trainerDiv = document.querySelector("[data-id='trainerDiv']");
  trainerDiv.querySelector("[data-id='trainerMessageText']").textContent = "";
  trainerDiv.querySelector("[data-id='trainerMessageText']").setAttribute("contenteditable", "true");
  trainerDiv.querySelector("[data-id='trainerMessageText']").pointerEvents = "auto";  
  trainerDiv.querySelector(".warningScreen").style.display = "none";
  trainerDiv.querySelector(".messageList").style.display = "flex";
  trainerDiv.querySelectorAll(".chatHistoryItem").forEach((item) => item.classList.remove("selected"));
  trainerDiv.querySelector(`[data-id='${trainerName}']`).classList.add("selected");
  const usersTrainersCollection = collection(db, "users", auth.currentUser.uid, "trainers");
  await getDocs(usersTrainersCollection, where("trainersName", '==', trainerName), where("metadata.status", "==", "incomplete")).then(async (trainersSnapshot) => {
    if (trainersSnapshot.docs.length > 0) {
      const trainerDoc = trainersSnapshot.docs[0];
      const messages = trainerDoc.data()?.messages ?? [];
      currentFanMessageNumber = trainerDoc.data()?.metadata?.currentFanMessageNumber ?? 0;
      currentModelMessageNumber = trainerDoc.data()?.metadata?.currentModelMessageNumber ?? 0;
      localStorage.setItem("trainerID", trainerDoc.id);
      await auth.currentUser.getIdToken(true).then(async (idToken) => {
        await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/trainers/getTrainer`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ idToken,
          },
          body: JSON.stringify({
            firebaseUID: auth.currentUser.uid,
            trainerName: trainerName
          })
        }).then((res) => res.json()).then(async (res) => {
          console.log(res)
          fanMessages = res.trainer.fanMessages;
          modelMessages = res.trainer.expectedModelMessages;
          trainerDiv.querySelector(".modelInfo").innerHTML = `
            <h2>Who are you chatting as?</h2>
            <div class='modelInfoRow'><p><b>Name:</b></p>&nbsp;<p>${res.trainer.modelDetails.name}</p></div>
            <div class='modelInfoRow'><p><b>Age:</b></p>&nbsp;<p>${res.trainer.modelDetails.age}</p></div>
            <div class='modelInfoRow'><p><b>Location:</b></p>&nbsp;<p>${res.trainer.modelDetails.location}</p></div>
            <div class='modelInfoRow'><p><b>Interests:</b></p>&nbsp;<p>${res.trainer.modelDetails.interests}</p></div>`
          trainerDiv.querySelector(".modelInfo").style.display = "flex";
          if (messages.length > 0) {
            messages.forEach((message, i) => {
              if (i === 0) {
                trainerDiv.querySelector("[data-id='trainerMessageList']").innerHTML += `
                  <div class="messageBox ai">
                    <div class="messageBoxRow">
                      <div class="monogram botly">
                      </div>
                      <p class="messageText">${message}</p>
                    </div>
                  </div>`           
              } else if ((i % 2) === 1) {
                trainerDiv.querySelector("[data-id='trainerMessageList']").innerHTML += `
                  <div class="messageBox ai">
                    <div class="messageBoxRow">
                      <div class="monogram">
                          <h2 class="messageMonogram">${monogram}</h2>
                      </div>
                      <p class="messageText">${message}</p>
                    </div>
                  </div>`
              } else {
                trainerDiv.querySelector("[data-id='trainerMessageList']").innerHTML += `
                  <div class="messageBox ai">
                    <div class="messageBoxRow">
                      <div class="monogram botly">
                      </div>
                      <p class="messageText">${message}</p>
                    </div>
                  </div>`
              }
              if (i === messages.length - 1) {
                document.querySelector(".fansUpcomingMessage").innerText = fanMessages[currentFanMessageNumber + 1]
                document.querySelector(".nextResponse").style.display = "flex";
              }
            })
            const chatHistoryDiv = $(`[data-id='trainerMessageList']`);
            chatHistoryDiv.animate({
                scrollTop: chatHistoryDiv.prop("scrollHeight")
            }, 500);
          } else {
            trainerDiv.querySelector("[data-id='trainerMessageList']").innerHTML += `
              <div class="messageBox ai">
                <div class="messageBoxRow">
                  <div class="monogram botly">
                  </div>
                  <p class="messageText">${fanMessages[0]}</p>
                </div>
              </div>`
            document.querySelector(".fansUpcomingMessage").innerText = fanMessages[currentFanMessageNumber + 1]
            document.querySelector(".nextResponse").style.display = "flex";
          }
        })
      })
    } else {
      await auth.currentUser.getIdToken(true).then(async (idToken) => {
        await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/trainers/getTrainer`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ idToken,
          },
          body: JSON.stringify({
            firebaseUID: auth.currentUser.uid,
            trainerName: trainerName
          })
        }).then((res) => res.json()).then(async (res) => {
          console.log(res)
          fanMessages = res.trainer.fanMessages;
          modelMessages = res.trainer.expectedModelMessages;
          trainerDiv.querySelector(".modelInfo").innerHTML = `
            <h2>Who are you chatting as?</h2>
            <div class='modelInfoRow'><p><b>Name:</b></p>&nbsp;<p>${res.trainer.modelDetails.name}</p></div>
            <div class='modelInfoRow'><p><b>Age:</b></p>&nbsp;<p>${res.trainer.modelDetails.age}</p></div>
            <div class='modelInfoRow'><p><b>Location:</b></p>&nbsp;<p>${res.trainer.modelDetails.location}</p></div>
            <div class='modelInfoRow'><p><b>Interests:</b></p>&nbsp;<p>${res.trainer.modelDetails.interests}</p></div>`
          trainerDiv.querySelector(".modelInfo").style.display = "flex";
          trainerDiv.querySelector("[data-id='trainerMessageList']").innerHTML = `
            <div class="messageBox ai">
              <div class="messageBoxRow">
                <div class="monogram botly">
                </div>
                <p class="messageText">${fanMessages[0]}</p>
              </div>
            </div>`
            document.querySelector(".fansUpcomingMessage").innerText = fanMessages[currentFanMessageNumber + 1]
            document.querySelector(".nextResponse").style.display = "flex";
          await fetch(`${import.meta.env.VITE_cloudFunctionURL}/DojoAPI/api/v1/trainers/startTrainer`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+ idToken,
            },
            body: JSON.stringify({
              firebaseUID: auth.currentUser.uid,
              trainerName: trainerName
            })
          }).then((res) => res.json()).then(async (res) => {
            console.log(res)
            localStorage.setItem("trainerID", res.trainerId);       
          })
          
        })
      })
    }
  })
}